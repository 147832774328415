import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import FormRemoteSelectInput, {
  SelectOptionType,
} from "components/FormRemoteSelectInput";
import InputForm from "components/InputForm";
import PercentageNumberInput from "components/PercentageInput";
import SwicthForm from "components/SwitchForm";
import useAuth from "contexts/useAuth";
import { queryClient } from "index";
import React from "react";
import { useForm } from "react-hook-form";
import { createPlan, findAllCompanys } from "services/api.service";
import { OriginTaxesDescription, TaxesOriginType, TaxesType } from "types/Fees";
export interface CreatePlanForm {
  description: string;
  name: string;
  company?: SelectOptionType;
  companyId: string;
  taxes: any;
  prazos: any;
  antecipationActive: any;
  antecipationPercent: any;
}

const CreatePlan: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const {
    userData: { isCustomerAdmin },
  } = useAuth();

  const toast = useToast();
  const { control, handleSubmit } = useForm<CreatePlanForm>({
    defaultValues: {},
  });

  const onSubmit = (data: CreatePlanForm) => {
    const fees = Object.keys(data.taxes).map((key) => ({
      origin: key as TaxesOriginType,
      type: TaxesType.PERCENTUAL,
      amount: data.taxes[key] as number,
      releaseInDays: +data.prazos[key],
      antecipationActive: data.antecipationActive[key],
      antecipationPercent: data.antecipationPercent[key],
    }));
    createPlan({
      companyId: data.company?.value,
      description: data.description,
      name: data.name,
      fees,
    })
      .then(() => {
        queryClient.refetchQueries(["findAllPlans"]);

        toast({
          title: "Sucesso",
          description: "Plano criado com sucesso",
          status: "success",
        });
        onClose();
      })
      .catch((error) => {
        toast({
          title: "Erro",
          description: error?.response?.data?.message || "Erro ao criar plano",
          status: "error",
        });
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW={{ lg: "1200px", md: "700px", sm: "400px" }}>
        <ModalHeader>{"Criar "} Plano</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing={3}>
              {!isCustomerAdmin && (
                <FormRemoteSelectInput
                  control={control}
                  name="company"
                  label="Empresa (Opcional)"
                  loadDataFn={({ value, cb }) =>
                    findAllCompanys({ filter: value, limit: 10, page: 0 }).then(
                      (retorno) => {
                        if (retorno?.registers?.length > 0) {
                          cb([
                            { label: "Escolher uma opção...", value: "" },
                            ...retorno.registers?.map((d: any) => ({
                              label: d.name,
                              value: d.id,
                            })),
                          ]);
                        } else {
                          cb([]);
                        }
                      }
                    )
                  }
                />
              )}
              <InputForm
                control={control}
                name="name"
                type="text"
                label="Nome"
                rules={{ required: "Campo obrigatório" }}
              />
              <InputForm
                control={control}
                name="description"
                type="text"
                label="Descrição"
                rules={{ required: "Campo obrigatório" }}
              />
            </VStack>
            <Text my={5} fontSize={18} fontWeight="bold">
              Configuração das Taxas
            </Text>

            <SimpleGrid columns={{ md: 2, sm: 1 }} spacing={3}>
              {[
                TaxesOriginType.ALL,
                TaxesOriginType.DEBIT_CARD,
                TaxesOriginType.DEBIT_VISA_MASTER,
                TaxesOriginType.DEBIT_ELO,
                TaxesOriginType.CREDIT_CARD,
                TaxesOriginType.CREDIT_VISA_MASTER,
                TaxesOriginType.CREDIT_ELO,
                TaxesOriginType.CASH,
                TaxesOriginType.PIX,
                TaxesOriginType.TICKET,
                TaxesOriginType.WITHDRAWAL,
                TaxesOriginType.VALE_ALIMENTACAO,
                TaxesOriginType.VALE_REFEICAO,
              ].map((paymentMethod) => {
                return (
                  <SimpleGrid
                    mb={5}
                    columns={{ md: 2, sm: 2 }}
                    spacing={3}
                    border={"1px solid #e2e2e2"}
                    padding={1}
                  >
                    <PercentageNumberInput
                      control={control}
                      placeholder="Ex: 5,99%"
                      label={
                        "Taxa - " +
                        OriginTaxesDescription[paymentMethod as TaxesOriginType]
                      }
                      name={`taxes.${paymentMethod}`}
                      rules={{
                        required: "Campo obrigatório",
                      }}
                    />
                    <InputForm
                      size="sm"
                      control={control}
                      placeholder="Quantidade em dias"
                      label={"Dias para liberação "}
                      type="number"
                      name={`prazos.${paymentMethod}`}
                      rules={{
                        required: "Campo obrigatório",
                      }}
                    />
                    <SwicthForm
                      control={control}
                      name={`antecipationActive.${paymentMethod}`}
                      label={"Antecipação Ativa"}
                    />
                    <PercentageNumberInput
                      control={control}
                      placeholder="Ex: 5,99%"
                      label={
                        "Taxa Antecipacão - " +
                        OriginTaxesDescription[paymentMethod as TaxesOriginType]
                      }
                      name={`antecipationPercent.${paymentMethod}`}
                      rules={{
                        validate: (value) => {
                          if (value && value > 100)
                            return "Não pode ser maior que 100";
                          return true;
                        },
                      }}
                    />
                  </SimpleGrid>
                );
              })}
            </SimpleGrid>
            <Text fontWeight="bold" fontSize={16}>
              Outras Taxas
            </Text>
            <SimpleGrid columns={{ md: 2, sm: 1 }} spacing={3}>
              {[
                TaxesOriginType.INTEGRATION_CREDIT_CARD,
                TaxesOriginType.INTEGRATION_DEBIT_CARD,
                TaxesOriginType.INTEGRATION_PIX,
                TaxesOriginType.INTEGRATION_CASH,
                TaxesOriginType.INTEGRATION_TICKET,
              ].map((paymentMethod) => {
                return (
                  <SimpleGrid
                    mb={5}
                    columns={{ md: 2, sm: 2 }}
                    spacing={3}
                    border={"1px solid #e2e2e2"}
                    padding={1}
                  >
                    <PercentageNumberInput
                      control={control}
                      placeholder="Ex: 5,99%"
                      label={
                        "Taxa - " +
                        OriginTaxesDescription[paymentMethod as TaxesOriginType]
                      }
                      name={`taxes.${paymentMethod}`}
                      rules={{
                        required: "Campo obrigatório",
                      }}
                    />
                    <InputForm
                      size="sm"
                      control={control}
                      placeholder="Quantidade em dias"
                      label={"Dias para liberação "}
                      type="number"
                      name={`prazos.${paymentMethod}`}
                      rules={{
                        required: "Campo obrigatório",
                      }}
                    />
                    <SwicthForm
                      control={control}
                      name={`antecipationActive.${paymentMethod}`}
                      label={"Antecipação Ativa"}
                    />
                    <PercentageNumberInput
                      control={control}
                      placeholder="Ex: 5,99%"
                      label={
                        "Taxa Antecipacão - " +
                        OriginTaxesDescription[paymentMethod as TaxesOriginType]
                      }
                      name={`antecipationPercent.${paymentMethod}`}
                      rules={{
                        validate: (value) => {
                          if (value && value > 100)
                            return "Não pode ser maior que 100";
                          return true;
                        },
                      }}
                    />
                  </SimpleGrid>
                );
              })}
            </SimpleGrid>
            <VStack>
              <Button
                mt={5}
                type="submit"
                variant={"outline"}
                w={`100%`}
                color={"green"}
              >
                Salvar
              </Button>
            </VStack>
          </form>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreatePlan;
