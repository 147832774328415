import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import InputForm from "components/InputForm";
import InputNumber from "components/InputNumber";
import PercentageNumberInput from "components/PercentageInput";
import SelectForm from "components/SelectForm";
import SwicthForm from "components/SwitchForm";
import { queryClient } from "index";
import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaPen } from "react-icons/fa";
import { addFeeToPlan, updateFee } from "services/api.service";
import { OriginTaxesDescription } from "types/Fees";
import { Fee } from "./Plan.types";

const EditFee: FC<{ fee: Fee; disabled: boolean }> = ({ fee, disabled }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [type, setType] = React.useState<string | null>(null);
  const toast = useToast();
  const [maxInstallments, setMaxInstallments] = React.useState(12);
  const [antecipateActive, setAntecipationActive] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit, setValue, reset } = useForm<{
    amount: number;
    maxFixed: number;
    minFixed: number;
    releaseInDays: number;

    type: {
      label: string;
      value: string;
    };
    origin: {
      label: string;
      value: string;
    };
    antecipationPercent: number;
    antecipationActive: boolean;
    installmentsType: string;
    maxInstallments: number;
    installments: {
      [key: string]: {
        fee: number;
      };
    };
  }>();

  const canParceled = [
    "INTEGRATION_CREDIT_CARD",
    "CREDIT_CARD",
    "CREDIT_VISA_MASTER",
    "CREDIT_ELO",
  ].includes(fee.origin);

  const save = async (dataForm) => {
    if (canParceled) {
      if (!dataForm.installments) {
        toast({
          title: "É necessário informar a porcentagem de cada parcela",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      }
    }

    setLoading(true);
    if (!fee.id) {
      addFeeToPlan(fee.planId, {
        ...dataForm,
        type: dataForm.type.value ?? type ?? fee.type,
        origin: dataForm.origin.value,
        releaseInDays: dataForm.releaseInDays ? +dataForm.releaseInDays : 0,
        maxInstallments: canParceled ? dataForm.maxInstallments : 0,
      })
        .then(() => {
          toast({
            title: "Taxa adicionada com sucesso",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          queryClient.refetchQueries({
            queryKey: ["findPlan"],
          });
          onClose();
        })
        .catch((error) => {
          toast({
            title: "Erro ao adicionar taxa",
            description: error.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      updateFee(fee.id, {
        ...dataForm,
        type: dataForm.type.value ?? type,
        origin: dataForm.origin.value,
        releaseInDays: dataForm.releaseInDays ? +dataForm.releaseInDays : 0,
        maxInstallments: canParceled ? dataForm.maxInstallments : 0,
      })
        .then(() => {
          toast({
            title: "Taxa atualizada com sucesso",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          queryClient.refetchQueries({
            queryKey: ["findPlan"],
          });
          onClose();
        })
        .catch((error) => {
          toast({
            title: "Erro ao atualizar taxa",
            description: error.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isOpen) {
      setAntecipationActive(fee.antecipationActive);

      reset({
        amount: +fee.amount,
        maxFixed: +fee.maxFixed,
        minFixed: +fee.minFixed,
        releaseInDays: +fee.releaseInDays,
        type: {
          label: fee.type,
          value: fee.type,
        },
        origin: {
          label: fee.origin,
          value: fee.origin,
        },
        antecipationPercent: +fee.antecipationPercent,
        antecipationActive: fee.antecipationActive,
        installmentsType: fee.installmentsType ?? "FROM_CUSTOMER",
        maxInstallments: fee.maxInstallments ?? 12,
        installments: fee.installments ?? {},
      });

      setMaxInstallments(fee.maxInstallments ?? 12);
      setValue("installments", fee.installments ?? {});
      setType(fee.type ?? "PERCENTUAL");
    }
  }, [fee, reset, isOpen]);

  return (
    <>
      <Button
        onClick={onOpen}
        size="sm"
        mt={2}
        rounded="sm"
        variant="outline"
        isDisabled={disabled}
        width="100%"
        colorScheme="blue"
      >
        <FaPen />
        <Text ml={2}>{fee.id ? "Editar" : "Adicionar"}</Text>
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Editar Plano</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={4}>
                <Select disabled value={fee.origin}>
                  <option value={fee.origin}>
                    {OriginTaxesDescription[fee.origin]}
                  </option>
                </Select>
                <Select
                  onChange={(data) => {
                    setValue("type", data.target.value as any);
                    setType(data.target.value as any);
                  }}
                >
                  <option value="PERCENTUAL">Percentual</option>
                  {!canParceled && <option value="FIXED">Fixo</option>}
                </Select>

                {!canParceled && (
                  <>
                    {type === "PERCENTUAL" ? (
                      <PercentageNumberInput
                        control={control}
                        name="amount"
                        label="Valor"
                      />
                    ) : (
                      <InputNumber
                        control={control}
                        name="amount"
                        label="Valor"
                      />
                    )}
                  </>
                )}
                <Text fontSize="sm" color="gray.500">
                  Caso possua valores configurados para parcelas, o valor que
                  será aplicado será o valor das parcelas.
                </Text>
                {!canParceled && (
                  <>
                    <InputNumber
                      control={control}
                      name="minFixed"
                      label="Valor Mínimo"
                    />
                    <InputNumber
                      control={control}
                      name="maxFixed"
                      label="Valor Máximo"
                    />
                  </>
                )}
                <InputForm
                  type="number"
                  control={control}
                  name="releaseInDays"
                  label="Dias para Liberação"
                />
                <SwicthForm
                  control={control}
                  name={"antecipationActive"}
                  label={"Antecipação Ativa"}
                  onChange={(e) => {
                    setValue("antecipationActive", e);
                    setAntecipationActive(e);
                  }}
                />
                {antecipateActive && (
                  <PercentageNumberInput
                    control={control}
                    rules={{
                      required: "Percentual de Antecipação é obrigatório",
                      min: {
                        value: 0,
                        message:
                          "Percentual de Antecipação deve ser maior que 0",
                      },
                      max: {
                        value: 100,
                        message:
                          "Percentual de Antecipação deve ser menor que 100",
                      },
                    }}
                    name="antecipationPercent"
                    label="Percentual de Antecipação"
                  />
                )}
                {canParceled && (
                  <>
                    <VStack w={"100%"}>
                      <Text>Configuração de Parcelas</Text>

                      <SelectForm
                        options={[
                          {
                            label: "Cobrar do cliente",
                            value: "FROM_CUSTOMER",
                          },
                          {
                            label: "Cobrar do pagador",
                            value: "FROM_PAYER",
                          },
                        ]}
                        control={control}
                        name="installmentsType"
                        label="Tipo de cobrança"
                      />

                      <InputForm
                        type="number"
                        control={control}
                        name="maxInstallments"
                        label="Máximo de parcelas"
                        rules={{
                          required: "Máximo de parcelas é obrigatório",
                          min: {
                            value: 1,
                            message: "Máximo de parcelas deve ser maior que 0",
                          },
                          max: {
                            value: 20,
                            message: "Máximo de parcelas deve ser menor que 12",
                          },
                        }}
                        onChange={(e) => {
                          if (e && +e > 20) {
                            toast({
                              title: "Máximo de parcelas deve ser menor que 20",
                              status: "error",
                              duration: 2000,
                              isClosable: true,
                            });
                            return;
                          }
                          setMaxInstallments(e && +e ? +e : 1);
                        }}
                      />

                      <Box>
                        <Text mb={4} fontWeight="medium" color="gray.600">
                          Informe a porcentagem de cada parcela
                        </Text>

                        <SimpleGrid
                          columns={{ base: 1, md: 2, lg: 3 }}
                          spacing={4}
                        >
                          {new Array(maxInstallments >= 1 ? maxInstallments : 0)
                            .fill(0)
                            .map((_, index) => (
                              <Box
                                key={index}
                                p={1}
                                _hover={{
                                  borderColor: "brand.500",
                                  shadow: "sm",
                                }}
                                transition="all 0.2s"
                              >
                                <PercentageNumberInput
                                  control={control}
                                  placeholder="0%"
                                  name={`installments.${index + 1}.fee`}
                                  label={
                                    index === 0
                                      ? "À vista"
                                      : `Parcela ${index + 1}`
                                  }
                                  rules={{
                                    required: "Este campo é obrigatório",
                                    min: {
                                      value: 0,
                                      message: "O valor mínimo é 0%",
                                    },
                                    max: {
                                      value: 100,
                                      message: "O valor máximo é 100%",
                                    },
                                  }}
                                />
                              </Box>
                            ))}
                        </SimpleGrid>
                      </Box>
                    </VStack>
                  </>
                )}
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(save)}
                >
                  {fee.id ? "Atualizar" : "Salvar"}
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default EditFee;
