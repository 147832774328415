import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  HStack,
  useToast,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import CustomButton from "components/CustomButton";
import FormRemoteSelectInput, {
  SelectOptionType,
} from "components/FormRemoteSelectInput";
import { queryClient } from "index";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { FaPen } from "react-icons/fa";
import {
  approveOnboarding,
  findAllCompanys,
  findAllPlans,
} from "services/api.service";

interface ApproveOnboardingProps {
  onboardingId: string;
}

interface ApproveOnboardingForm {
  company: SelectOptionType;
  plan: SelectOptionType;
}

const ApproveOnboarding: FC<ApproveOnboardingProps> = ({ onboardingId }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit } = useForm<ApproveOnboardingForm>();

  const save = async (dataForm: ApproveOnboardingForm) => {
    setLoading(true);
    approveOnboarding(onboardingId, {
      planId: +dataForm?.plan?.value,
      companyId: dataForm?.company?.value,
    })
      .then(() => {
        queryClient.refetchQueries({
          queryKey: ["onboarding"],
          type: "active",
        });
        onClose();
        toast({
          title: "Sucesso",
          description: "Onboarding aprovado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Erro",
          description:
            err?.response?.data?.message || "Erro ao aprovar onboarding",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <CustomButton
        onClick={onOpen}
        variant="solid"
        colorScheme="green"
        leftIcon={<FaPen />}
      >
        Aprovar
      </CustomButton>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Aprovar onboarding</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack>
                <FormRemoteSelectInput
                  control={control}
                  label="Plano"
                  registerOptions={{
                    required: "Campo obrigatório",
                  }}
                  loadDataFn={({ value, cb }) =>
                    findAllPlans({ filter: value, limit: 20, page: 0 }).then(
                      (retorno) => {
                        if (retorno?.registers?.length > 0) {
                          cb(
                            retorno.registers?.map((d: any) => ({
                              label: d.name,
                              value: d.id,
                            }))
                          );
                        } else {
                          cb([]);
                        }
                      }
                    )
                  }
                  name="plan"
                />
                <FormRemoteSelectInput
                  control={control}
                  label="Empresa"
                  registerOptions={{
                    required: "Campo obrigatório",
                  }}
                  loadDataFn={({ value, cb }) =>
                    findAllCompanys({ filter: value, limit: 20, page: 0 }).then(
                      (retorno) => {
                        if (retorno?.registers?.length > 0) {
                          cb(
                            retorno.registers?.map((d: any) => ({
                              label: d.name,
                              value: d.id,
                            }))
                          );
                        } else {
                          cb([]);
                        }
                      }
                    )
                  }
                  name="company"
                />
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <CustomButton
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Fechar
                </CustomButton>
                <CustomButton
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(save)}
                >
                  Aprovar
                </CustomButton>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default ApproveOnboarding;
