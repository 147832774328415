import {
  Box,
  Divider,
  HStack,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import Card from "components/card/Card";
import useAuth, { UserPermissions } from "contexts/useAuth";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";
import { findOnboarding } from "services/api.service";
import { maskDocument, maskPhone } from "utils/formatters";
import { OnboardingStatusRender } from "..";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import ConsultCnpjData from "./OnboaringDocumentInfo";
import RegisterOnboardingCompany from "./RegisterCompany";
import ApproveOnboarding from "./ApproveOnboarding";
import RejectOnboarding from "./RejectOnboarding";

export default function Onboarding() {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useQuery(["onboarding", id], () =>
    findOnboarding(id)
  );

  const { hasPermission } = useAuth();
  const hasPermWrite = hasPermission(UserPermissions.ONBOARDING_WRITE);

  if (isLoading) {
    return (
      <>
        <Box
          pt={{ base: "80px", md: "80px", xl: "80px" }}
          w="100%"
          textAlign="center"
        >
          <Spinner />
          Carregando...
        </Box>
      </>
    );
  }

  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <Card
        flexDirection="column"
        w="100%"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <HStack justifyContent="space-between" w="100%">
          <Text fontSize="xl" mb={5} fontWeight="bold">
            Dados do onboarding
          </Text>
          <HStack>
            {hasPermWrite && data?.status === "COMPLETED" && (
              <RegisterOnboardingCompany onboardingId={id} />
            )}
            {hasPermWrite && data?.status === "COMPLETED" && (
              <RejectOnboarding onboardingId={id} />
            )}
            {hasPermWrite && data?.status === "COMPLETED" && (
              <ApproveOnboarding onboardingId={id} />
            )}
          </HStack>
        </HStack>
        <Divider mb={2} />
        <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} gap={3}>
          <VStack alignItems="start">
            <Text color="gray.500">Status</Text>
            <Text>{<OnboardingStatusRender status={data?.status} />}</Text>
          </VStack>
          <VStack alignItems="start">
            <Text color="gray.500">Nome</Text>
            <Text>{data?.name}</Text>
          </VStack>

          <VStack alignItems="start">
            <Text color="gray.500">Segundo nome</Text>
            <Text>{data?.secondName}</Text>
          </VStack>
          <VStack alignItems="start">
            <Text color="gray.500">Email</Text>
            <Text>{data?.email}</Text>
          </VStack>
          <VStack alignItems="start">
            <Text color="gray.500">Documento</Text>
            <Text>{maskDocument(data?.document)}</Text>
          </VStack>
          <VStack alignItems="start">
            <Text color="gray.500">Telefone</Text>
            <Text>{maskPhone(data?.phone)}</Text>
          </VStack>
        </SimpleGrid>
        <Divider my={6} />{" "}
        <Text fontSize="xl" mb={5} fontWeight="bold">
          Responsável
        </Text>
        <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} gap={3}>
          <VStack alignItems="start">
            <Text color="gray.500">Nome</Text>
            <Text>{data?.holder.name}</Text>
          </VStack>
          <VStack alignItems="start">
            <Text color="gray.500">Nome da mãe</Text>
            <Text>{data?.holder.motherName}</Text>
          </VStack>
          <VStack alignItems="start">
            <Text color="gray.500">Data de nascimento</Text>
            <Text>
              {DateTime.fromISO(data?.holder.birthDate).toFormat("dd/MM/yyyy")}
            </Text>
          </VStack>

          <VStack alignItems="start">
            <Text color="gray.500">Email</Text>
            <Text>{data?.holder.email}</Text>
          </VStack>
          <VStack alignItems="start">
            <Text color="gray.500">Documento</Text>
            <Text>{maskDocument(data?.holder.document)}</Text>
          </VStack>
          <VStack alignItems="start">
            <Text color="gray.500">Telefone</Text>
            <Text>{maskPhone(data?.holder.phone)}</Text>
          </VStack>
        </SimpleGrid>
        <Divider my={6} />
        <Text fontSize="xl" mb={5} fontWeight="bold">
          Endereço
        </Text>
        <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} gap={3}>
          <VStack alignItems="start">
            <Text color="gray.500">Endereço</Text>
            <Text>{data?.address.address}</Text>
          </VStack>
          <VStack alignItems="start">
            <Text color="gray.500">Número</Text>
            <Text>{data?.address.number}</Text>
          </VStack>
          <VStack alignItems="start">
            <Text color="gray.500">Complemento</Text>
            <Text>{data?.address.complement}</Text>
          </VStack>
          <VStack alignItems="start">
            <Text color="gray.500">Bairro</Text>
            <Text>{data?.address.neigherhood}</Text>
          </VStack>
          <VStack alignItems="start">
            <Text color="gray.500">Cidade</Text>
            <Text>{data?.address.cep.City.name}</Text>
          </VStack>
          <VStack alignItems="start">
            <Text color="gray.500">Estado</Text>
            <Text>{data?.address.cep.state.name}</Text>
          </VStack>
        </SimpleGrid>
        <Divider my={6} />
        <Text fontSize="xl" mb={5} fontWeight="bold">
          Imagens
        </Text>
        <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} gap={3}>
          <PhotoProvider>
            <VStack alignItems="start">
              <Text color="gray.500">RG Frente</Text>
              <PhotoView src={data?.frontImageUrl}>
                <img src={data?.frontImageUrl} alt="" />
              </PhotoView>
            </VStack>
            <VStack alignItems="start">
              <Text color="gray.500">RG Verso</Text>
              <PhotoView src={data?.backImageUrl}>
                <img src={data?.backImageUrl} alt="" />
              </PhotoView>
            </VStack>
            <VStack alignItems="start">
              <Text color="gray.500">Selfie</Text>
              <PhotoView src={data?.selfieImageUrl} height={10} width={100}>
                <img src={data?.selfieImageUrl} alt="" />
              </PhotoView>
            </VStack>
          </PhotoProvider>
        </SimpleGrid>
        <Divider my={6} />
        <ConsultCnpjData document={data?.document} />
      </Card>
    </Box>
  );
}
