// Chakra imports
import {
  Box,
  ButtonGroup,
  IconButton,
  SimpleGrid,
  Tooltip,
} from "@chakra-ui/react";

import { useMemo } from "react";
import { findAllEyeTransactions } from "services/api.service";
import { Column } from "react-table";
import { DateTime } from "luxon";
import TableComponent from "components/TableComponent";
import { FaEye } from "react-icons/fa";
import useAuth, { UserPermissions } from "contexts/useAuth";
import { maskCurrency } from "utils/number";
import { useHistory } from "react-router-dom";

export default function EyeTransactions() {
  const history = useHistory();

  const { hasPermission } = useAuth();
  const writePermission = hasPermission(UserPermissions.TERMINAL_WRITE);

  const columns = useMemo(
    () =>
      [
        {
          Header: "Nome cliente",
          accessor: "customer.name",
        },
        {
          Header: "Nome Menu",
          accessor: "eyeIntegration.menuName",
        },

        {
          Header: "Valor",
          accessor: "amount",
          Cell: ({ value }) => {
            return maskCurrency(+value);
          },
        },
        {
          accessor: "createdAt",
          Header: "Data de criação",
          Cell: ({ value }) => {
            return DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm:ss");
          },
        },

        {
          Header: "Ações",
          accessor: "actions",
        },
      ] as Column[],
    []
  );

  const actions = (row: any) => (
    <ButtonGroup>
      {writePermission && (
        <Tooltip label="Visualizar transação" aria-label="Visualizar transação">
          <IconButton
            aria-label="Ver transação"
            rounded="md"
            size="sm"
            variant="outline"
            icon={<FaEye />}
            onClick={() => history.push(`/admin/eye-transactions/${row.id}`)}
          />
        </Tooltip>
      )}
    </ButtonGroup>
  );

  // Chakra Color Mode
  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <TableComponent
          columnsData={columns}
          queryFn={findAllEyeTransactions}
          queryKey="findAllEyeTransactions"
          filterable={["customerId"]}
          actions={actions}
        />
      </SimpleGrid>
    </Box>
  );
}
