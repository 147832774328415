import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  HStack,
  Button,
  VStack,
  useToast,
} from "@chakra-ui/react";
import FormRemoteSelectInput, {
  SelectOptionType,
} from "components/FormRemoteSelectInput";
import useAuth from "contexts/useAuth";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import {
  findAllClients,
  findAllCompanys,
  registerTerminalToCustomer,
} from "services/api.service";

const LabelItem: FC<{
  label: string;
  value: string;
}> = ({ label, value }) => {
  return (
    <VStack mb={3} width="100%" alignItems="start">
      <Text as={"small"} fontSize="sm" color="gray.600">
        {label}
      </Text>
      <Text mt={"0px !important"} fontSize="md">
        {value || "---"}
      </Text>
    </VStack>
  );
};

const ModalSelectMaquineta: FC<{
  data: {
    id: string;
    name: string;
    number: string;
    [key: string]: any;
  };
  onClose: () => void;
}> = ({ data, onClose }) => {
  const toast = useToast();
  const { userData } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [companyId, setCompanyId] = React.useState<string | undefined>();
  const { control, handleSubmit, setValue } = useForm<{
    customer: SelectOptionType;
    company: SelectOptionType;
  }>({
    defaultValues: {
      customer: data?.MaquinetasCustomers?.[0]?.Customer?.name
        ? {
            label: data?.MaquinetasCustomers?.[0]?.Customer?.name,
            value: data?.MaquinetasCustomers?.[0]?.Customer?.id,
          }
        : undefined,
    },
  });

  const save = async (dataForm: {
    customer: SelectOptionType;
    company: SelectOptionType;
  }) => {
    setLoading(true);
    registerTerminalToCustomer({
      customerId: dataForm.customer.value,
      maquinetaId: data.id,
      companyId: dataForm.company?.value
    })
      .then(() => {
        onClose();
        toast({
          title: "Sucesso",
          description: "Vínculo alterado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoading(false);
        toast({
          title: "Erro",
          description:
            err?.response?.data?.message || "Erro ao alterar vínculo",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <Modal isOpen={!!data} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Vincular Terminal</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack mb={5} width="100%" alignItems="start">
            <LabelItem label="Nome" value={data?.name} />
            <LabelItem label="Número" value={data?.number} />
          </VStack>
          {!userData.isCustomerAdmin && (
            <FormRemoteSelectInput
              control={control}
              name="company"
              registerOptions={{
                required: "Campo obrigatório",
              }}
              label="Empresa"
              onChange={(value) => {
                setValue("company", value);
                setCompanyId(value?.value);
              }}
              loadDataFn={({ value, cb }) =>
                findAllCompanys({ filter: value, limit: 10, page: 0 }).then(
                  (retorno) => {
                    if (retorno?.registers?.length > 0) {
                      cb(
                        retorno.registers?.map((d: any) => ({
                          label: d.name,
                          value: d.id,
                        }))
                      );
                    } else {
                      cb([]);
                    }
                  }
                )
              }
            />
          )}
          <FormRemoteSelectInput
            control={control}
            label="Cliente"
            required
            isDisabled={!userData.isCustomerAdmin && !companyId}
            registerOptions={{ required: "Esse campo é obrigatório" }}
            loadDataFn={({ value, cb }) =>
              findAllClients({ filter: value, companyId }).then((retorno) => {
                if (retorno?.registers?.length > 0) {
                  cb(
                    retorno.registers?.map((d: any) => ({
                      label: d.name,
                      value: d.id,
                    }))
                  );
                } else {
                  cb([]);
                }
              })
            }
            name="customer"
          />
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            <Button
              colorScheme="red"
              isLoading={loading}
              onClick={() => {
                onClose();
              }}
            >
              Cancelar
            </Button>
            <Button
              colorScheme="green"
              isLoading={loading}
              onClick={handleSubmit(save)}
            >
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalSelectMaquineta;
