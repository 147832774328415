import { useToast } from "@chakra-ui/react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import api, {
  LoginDataResponse,
  loginService,
  refreshUserData,
} from "services/api.service";
import jwt_decode from "jwt-decode";

export const AUTHSTORAGE_KEY = `asdasd1as32d1as6d54wq6d132dsg1r`;

type AuthContextType = {
  userData: AuthUserData;
  refresh: () => void;
  hasPermission: (...permission: UserPermissions[]) => boolean;
  signIn: (args: {
    username: string;
    history: any;
    twoFa?: string;
    password: string;
    captchaKey: string;
    callbackUrl?: string;
  }) => Promise<AuthUserData>;
  signOut: () => Promise<void>;
};

export enum UserPermissions {
  PLAN_READ = "plan.read",
  PLAN_WRITE = "plan.write",
  DASHBOARD_READ = "dashboard.read",
  BALANCES_READ = "balances.read",
  BALANCES_WRITE = "balances.write",
  MOVEMENTS_READ = "movements.read",
  MOVEMENTS_WRITE = "movements.write",
  CHARGES_READ = "charges.read",
  CHARGES_CREATE = "charges.create",
  CHARGES_WRITE = "charges.write",
  CUSTOMERS_READ = "customers.read",
  CUSTOMERS_WRITE = "customers.write",
  CUSTOMERS_BALANCE = "customers.balance",
  CUSTOMERS_TRANSFER = "customers.transfer",
  CUSTOMERS_CREATE = "customers.create",
  USERS_READ = "users.read",
  USERS_WRITE = "users.write",
  MAQUINETAS_READ = "maquinetas.read",
  MAQUINETAS_WRITE = "maquinetas.write",
  TERMINAL_READ = "terminais.read",
  TERMINAL_WRITE = "terminais.write",
  WITHDRAWALS_READ = "withdrawals.read",
  WITHDRAWALS_WRITE = "withdrawals.write",
  WITHDRAWALS_AUTOMATIC = "withdrawals.automatic",
  INSURANCES_READ = "insurances.read",
  INSURANCES_WRITE = "insurances.write",
  WALLET_READ = "wallet.read",
  WALLET_WRITE = "wallet.write",
  FEE_READ = "fee.read",
  FEE_WRITE = "fee.write",
  INTEGRATIONS_READ = "integrations.read",
  INTEGRATIONS_WRITE = "integrations.write",
  COMPANYS_READ = "companys.read",
  COMPANYS_WRITE = "companys.write",
  ONBOARDING_READ = "onboarding.read",
  ONBOARDING_WRITE = "onboarding.write",
}

export enum PermDescriptions {
  "dashboard" = "Dashboard",
  "balances" = "Extrato",
  "movements" = "Vendas",
  "charges" = "Cobranças",
  "customers" = "Clientes",
  "users" = "Usuários",
  "maquinetas" = "Terminais",
  "withdrawals" = "Saque",
  "insurances" = "Seguros",
  "onboarding" = "Onboarding",
  "wallet" = "Carteira",
  "fee" = "Tarifas",
  "integrations" = "Integrações",
  "companys" = "Empresas",
  "plan" = "Planos",
  terminais = "Terminais",
}

export enum ReadWrite {
  "read" = "Leitura",
  "write" = "Escrita",
  "balance" = "Saldo",
  "transfer" = "Transferência",
  "create" = "Criação",
  "automatic" = "Automático",
}

export type AuthUserData = {
  isAuth: boolean;
  isCustomerAdmin: boolean;
  authorization: string;
  name: string;
  email: string;
  twoFa: boolean;
  permissions: UserPermissions[];
  id: string;
  exp: number;
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);
export function delAuthStorage(): void {
  localStorage.removeItem(AUTHSTORAGE_KEY);
  localStorage.removeItem("Authorization");
}

export default function useAuth() {
  return useContext(AuthContext);
}

export function getAuthStorage(): AuthUserData {
  if (typeof localStorage === `undefined`) {
    return {} as AuthUserData;
  }
  try {
    const localStorageData = localStorage.getItem(AUTHSTORAGE_KEY);

    return localStorageData
      ? JSON.parse(localStorageData)
      : ({ isAuth: false } as AuthUserData);
  } catch (error) {
    console.error(`localStorage get error`, error);
    return { isAuth: false } as AuthUserData;
  }
}

export function setAuthStorage(value: AuthUserData): void {
  return localStorage.setItem(AUTHSTORAGE_KEY, JSON.stringify(value));
}

export function AuthProvider({ children }: any) {
  const toast = useToast();
  const [userData, setUserData] = useState<AuthUserData>(
    getAuthStorage() || ({} as AuthUserData)
  );

  const signIn = useCallback(
    async ({
      username,
      password,
      history,
      twoFa,
      callbackUrl = `/admin/default`,
      captchaKey,
    }: {
      username: string;
      history: any;
      twoFa?: string;
      password: string;
      callbackUrl?: string;
      captchaKey: string;
    }): Promise<AuthUserData> => {
      const authResponse = await loginService(
        username,
        password,
        captchaKey,
        twoFa
      );
      toast({
        title: "Sucesso",
        description: "Login realizado com sucesso",
        status: "success",
        duration: 5000,
        position: "top",
        isClosable: true,
      });

      const data = await updateUserData(authResponse).catch((error) => {
        throw error;
      });
      history.push(callbackUrl);
      return data;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const signOut = useCallback(async () => {
    try {
      setUserData({} as AuthUserData);
      setAuthStorage({} as AuthUserData);
      api.defaults.headers.Authorization = "";
      localStorage.removeItem("Authorization");
      window.location.href = "/#/auth";
    } catch (error) {
      throw error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = useCallback(() => {
    refreshUserData().then(async (data) => {
      await updateUserData(data);
    });
  }, []);

  const updateUserData = useCallback(
    async (authResponse: LoginDataResponse) => {
      const token = jwt_decode(authResponse.token) as any;
      const exp = token.exp ?? Date.now() + 1000 * 60 * 60 * 24;
      const data = {
        authorization: authResponse.token,
        email: authResponse.email,
        isAuth: true,
        isCustomer: false,
        isCustomerAdmin: !!authResponse.companyId,
        id: authResponse.id,
        name: authResponse.name,
        twoFa: authResponse.twoFa,
        permissions: authResponse.permissions,
        exp: exp,
      };
      setUserData(data);
      setAuthStorage(data);
      localStorage.setItem("Authorization", data.authorization);
      api.defaults.headers.Authorization = "Bearer " + data.authorization;
      return data;
    },
    []
  );

  useEffect(() => {
    if (userData.isAuth !== undefined) {
      if (userData?.authorization) {
        const token = jwt_decode(userData.authorization) as any;
        if (token.exp) {
          const now = new Date();
          const exp = new Date(token.exp * 1000);
          if (now > exp) {
            delAuthStorage();
            window.location.href = "/#/auth";
          }
        }
      } else {
        window.location.href = "/#/auth";
      }
    }
  }, [userData, refresh]);

  useEffect(() => {
    // Set up interval to refresh token every 15 minutes
    const interval = setInterval(refresh, 15 * 1000 * 60);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [refresh]);

  const hasPermission = useCallback(
    (...permission: UserPermissions[]) => {
      return permission.some((perm) => userData.permissions?.includes(perm));
    },
    [userData]
  );

  return (
    <AuthContext.Provider
      value={{
        userData,
        signIn,
        signOut,
        hasPermission,
        refresh,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
