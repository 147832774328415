export enum OriginTaxesDescription {
  DEBIT_CARD = "Cartão de Débito",
  DEBIT_VISA_MASTER = "Cartão de Débito Visa/Master",
  DEBIT_ELO = "Cartão de Débito Elo",
  CREDIT_CARD = "Cartão de Crédito (Outros)",
  CREDIT_VISA_MASTER = "Cartão de Crédito Visa/Master",
  CREDIT_ELO = "Cartão de Crédito Elo",
  CASH = "Dinheiro",
  PIX = "Pix",
  TICKET = "Boleto",
  ALL = "Outros",
  VALE_ALIMENTACAO = "Vale Alimentação",
  VALE_REFEICAO = "Vale Refeição",
  MAIN = "Taxa Default(Caso não tenha configuração)",
  WITHDRAWAL = "Taxa de Saque",
  INTEGRATION_TICKET = "Integração Boleto",
  INTEGRATION_PIX = "Integração Pix",
  INTEGRATION_CREDIT_CARD = "Integração Cartão de Crédito",
  INTEGRATION_DEBIT_CARD = "Integração Cartão de Débito",
  INTEGRATION_CASH = "Integração Dinheiro",
  SPREAD = "Spread",
}

export enum TaxesOriginType {
  "ALL" = "ALL",
  "DEBIT_CARD" = "DEBIT_CARD",
  "DEBIT_VISA_MASTER" = "DEBIT_VISA_MASTER",
  "DEBIT_ELO" = "DEBIT_ELO",
  "CREDIT_VISA_MASTER" = "CREDIT_VISA_MASTER",
  "CREDIT_ELO" = "CREDIT_ELO",
  "CREDIT_CARD" = "CREDIT_CARD",
  "CASH" = "CASH",
  "PIX" = "PIX",
  "MAIN" = "MAIN",
  "WITHDRAWAL" = "WITHDRAWAL",
  "TICKET" = "TICKET",
  "INTEGRATION_TICKET" = "INTEGRATION_TICKET",
  "INTEGRATION_PIX" = "INTEGRATION_PIX",
  "INTEGRATION_CREDIT_CARD" = "INTEGRATION_CREDIT_CARD",
  "INTEGRATION_DEBIT_CARD" = "INTEGRATION_DEBIT_CARD",
  "INTEGRATION_CASH" = "INTEGRATION_CASH",
  "VALE_ALIMENTACAO" = "VALE_ALIMENTACAO",
  "VALE_REFEICAO" = "VALE_REFEICAO",
}

export enum TaxesType {
  FIXED = "FIXED",
  PERCENTUAL = "PERCENTUAL",
}

export enum TaxesTypeDescriptions {
  FIXED = "Fixa",
  PERCENTUAL = "Porcentagem",
}
