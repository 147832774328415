import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  HStack,
  useToast,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import CustomButton from "components/CustomButton";
import { queryClient } from "index";
import React, { FC } from "react";
import { FaPen } from "react-icons/fa";
import { rejectOnboarding } from "services/api.service";

interface RejectOnboardingProps {
  onboardingId: string;
}

const RejectOnboarding: FC<RejectOnboardingProps> = ({ onboardingId }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);

  const save = async () => {
    setLoading(true);
    rejectOnboarding(onboardingId)
      .then(() => {
        queryClient.refetchQueries({
          queryKey: ["onboarding"],
          type: "active",
        });
        onClose();
        toast({
          title: "Sucesso",
          description: "Onboarding reprovado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Erro",
          description:
            err?.response?.data?.message || "Erro ao rejeitar onboarding",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <CustomButton
        onClick={onOpen}
        variant="outline"
        colorScheme="red"
        leftIcon={<FaPen />}
      >
        Recusar
      </CustomButton>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Rejeitar onboarding</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>Tem certeza que deseja recusar o onboarding?</Text>
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <CustomButton
                  colorScheme="yellow"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Fechar
                </CustomButton>
                <CustomButton
                  colorScheme="red"
                  isLoading={loading}
                  onClick={save}
                >
                  Recusar
                </CustomButton>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default RejectOnboarding;
