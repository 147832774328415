import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem,
  HStack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
// Custom components
import Card from "components/card/Card";
import FileSaver from "file-saver";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaUpload } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import { useDebounce } from "react-use";
import {
  findAllClients,
  findAllClientsRecurrences,
  findAllCompanys,
  findAllTerminals,
} from "services/api.service";
import FormRemoteSelectInput from "./FormRemoteSelectInput";
import InputForm from "./InputForm";
import TableList from "./Table";
import SelectForm from "./SelectForm";
import { DateTime } from "luxon";
import { PiFunnel } from "react-icons/pi";
import CustomButton from "./CustomButton";
import useAuth from "contexts/useAuth";
import MiniStatistics from "./card/MiniStatistics";
import { BalanceTypeDescription } from "views/admin/balances/balances-pending";
import { maskCurrency } from "utils/number";

const paramsToQuery = [
  "pageIndex",
  "pageSize",
  "orderBy",
  "filter",
  "customerId",
  "maquinetaId",
  "companyId",
  "initialDate",
  "finalDate",
  "charges",
  "promoterId",
];

function objectToBase64(obj: FilterTable) {
  // Convert object to JSON string
  const jsonString = JSON.stringify(obj);

  // Encode JSON string to Base64
  const base64String = btoa(jsonString);

  return base64String;
}

function base64ToObject(base64String) {
  // Decode Base64 string to JSON string
  const jsonString = atob(base64String);

  // Parse JSON string back to JavaScript object
  return JSON.parse(jsonString);
}

interface Aggregate {
  total: number;
  key: string;
}

interface FilterTable {
  pageIndex: number;
  pageSize: number;
  orderBy: any[];
  filter: string;
  customerId?: string;
  maquinetaId?: string;
  companyId?: string;
  initialDate?: string;
  finalDate?: string;
  charges?: string;
  promoterId?: string;
  query?: Record<string, any>;
}

export default function TableComponent(props: {
  queryFn: Function;
  columnsData: any[];
  exportPdf?: boolean;
  exportCsv?: boolean;
  queryKey: string;
  filterable?: string[];
  actions: any;
  right?: any;
  setSelectedRows?: any;
  enableSelect?: boolean;
  label?: string;
  aggregateSelection?: {
    key: string;
    label: string;
  }[];
}) {
  const toast = useToast();
  const [isLoaded, setIsLoaded] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const {
    columnsData,
    label,
    queryFn,
    queryKey,
    actions,
    right,
    filterable = [],
    setSelectedRows,
    enableSelect,
    exportPdf,
    exportCsv,
    aggregateSelection,
  } = props;
  const loc = useLocation<FilterTable>();
  const history = useHistory();
  const params = new URLSearchParams(loc.search);
  const entries = params.get("queryfilter")
    ? base64ToObject(params.get("queryfilter"))
    : {};

  const [filter, setFilter] = React.useState<FilterTable>({
    pageIndex: 0,
    pageSize: 20,
    orderBy: [],
    ...(filterable?.includes("createdAt") && {
      initialDate: DateTime.now().startOf("month").toISO(),
      finalDate: DateTime.now().endOf("month").toISO(),
    }),
    filter: "",
  });
  const [busca, setBusca] = React.useState("");
  const { control, setValue } = useForm();

  useEffect(() => {
    if (!isLoaded) {
      if (entries.query) {
        Object.keys(entries.query).map((key) => {
          setValue(key, entries.query[key]);
          return key;
        });
      }
      if (entries.filter) {
        setBusca(entries.filter);
      }

      const fieldToAdd = {};

      for (const filterableItem of filterable ?? []) {
        if (params.get(filterableItem)) {
          fieldToAdd[filterableItem] = params.get(filterableItem);
        }
      }

      setFilter((prev) => ({ ...prev, ...fieldToAdd, ...entries }));
      setIsLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entries]);

  useDebounce(
    () => {
      setFilter((prev) => ({ ...prev, filter: busca }));
    },
    500,
    [busca]
  );

  const { data, isLoading } = useQuery<{
    pages: number;
    registers: any[];
    aggregate: Aggregate[];
  }>(
    [queryKey, filter],
    () =>
      queryFn({
        page: filter.pageIndex,
        filter: filter.filter,
        customerId: filter.customerId,
        companyId: filter.companyId,
        maquinetaId: filter.maquinetaId,
        promoterId: filter.promoterId,
        limit: filter.pageSize,
        orderBy: filter.orderBy?.[0]?.id,
        direction: filter.orderBy?.[0]?.desc ? "desc" : "asc",
        initialDate: filter.initialDate,
        finalDate: filter.finalDate,
        query: filter.query,
        charges: filter.charges,
      }),
    {
      keepPreviousData: true,
    }
  );

  const exportToFile = (type: "pdf" | "csv") => {
    if (!filter.initialDate || !filter.finalDate) {
      toast({
        title: "Erro",
        description: "Selecione uma data inicial e final",
        status: "error",
        duration: 9000,
        position: "top",
        isClosable: true,
      });
      return;
    }
    setLoading(true);
    queryFn(
      {
        page: filter.pageIndex,
        filter: filter.filter,
        customerId: filter.customerId,
        companyId: filter.companyId,
        promoterId: filter.promoterId,
        maquinetaId: filter.maquinetaId,
        limit: filter.pageSize,
        orderBy: filter.orderBy?.[0]?.id,
        direction: filter.orderBy?.[0]?.desc ? "desc" : "asc",
        initialDate: filter.initialDate,
        finalDate: filter.finalDate,
        query: filter.query,
        charges: filter.charges,
        exportCsv: type === "csv",
      },
      true
    )
      .then((res: any) => {
        setLoading(false);
        if (type === "pdf") {
          FileSaver(res, "relatorio.pdf");
        } else {
          FileSaver(res, "relatorio.csv", {
            autoBom: false,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        toast({
          title: "Erro",
          description: "Ocorreu um erro ao gerar o relatório",
          status: "error",
          duration: 9000,
          position: "top",
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    const existsDiff = Object.keys(filter).some((key) => {
      if (!paramsToQuery.includes(key)) {
        return false;
      }
      return String(filter[key]) !== String(params.get(key));
    });
    if (existsDiff) {
      const fieldToAdd = {};

      for (const filterableItem of filterable ?? []) {
        if (params.get(filterableItem)) {
          fieldToAdd[filterableItem] = params.get(filterableItem);
        }
      }
      history.replace({
        search: new URLSearchParams({
          ...fieldToAdd,
          queryfilter: objectToBase64({ ...filter, ...fieldToAdd }),
        }).toString(),
        state: { ...filter, ...fieldToAdd },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <>
      <Card flexDirection="column" w="100%" p={0} pt={5} pb={3} px="0px">
        <HStack
          px={5}
          justifyContent="space-between"
          flexDirection={{
            base: "column",
            md: "row",
          }}
        >
          <Text fontWeight={700} fontSize="xl">
            {label}
          </Text>
          <HStack
            px={5}
            justifyContent="flex-end"
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <CustomButton leftIcon={<PiFunnel />} onClick={onOpen}>
              Filtros
            </CustomButton>
            <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Realizar filtros</DrawerHeader>

                <DrawerBody>
                  <VStack>
                    <InputForm
                      placeholder="Filtrar por palavras chaves..."
                      control={control}
                      name="filter"
                      value={busca}
                      onChange={(value) => setBusca(value)}
                      label="Filtrar"
                      type="text"
                      size="sm"
                    />

                    {columnsData
                      .filter((item) => item.filter)
                      .map((itemColumn) => {
                        return (
                          <>
                            <SelectForm
                              placeholder={`Filtrar por ${itemColumn.Header}...`}
                              control={control}
                              name={itemColumn.accessor}
                              label={itemColumn.Header}
                              onChange={(value) =>
                                setFilter((prev) => ({
                                  ...prev,
                                  query: {
                                    ...prev.query,
                                    [itemColumn.accessor]: value,
                                  },
                                }))
                              }
                              options={itemColumn.values.map((key) => ({
                                label: key.label,
                                value: key.value,
                              }))}
                            />
                          </>
                        );
                      })}

                    {filterable?.includes("createdAt") && (
                      <>
                        <InputForm
                          control={control}
                          name="initialDate"
                          label="Data Inicial"
                          type="datetime-local"
                          value={filter.initialDate?.substring(0, 16)}
                          size="sm"
                          onChange={(value) =>
                            setFilter((prev) => ({
                              ...prev,
                              initialDate: DateTime.fromISO(value).toISO(),
                            }))
                          }
                        />
                        <InputForm
                          control={control}
                          name="finalDate"
                          label="Data Final"
                          value={filter.finalDate.substring(0, 16)}
                          type="datetime-local"
                          size="sm"
                          onChange={(value) =>
                            setFilter((prev) => ({
                              ...prev,
                              finalDate: DateTime.fromISO(value).toISO(),
                            }))
                          }
                        />
                      </>
                    )}

                    {!userData.isCustomerAdmin &&
                      filterable?.includes("companyId") && (
                        <FormRemoteSelectInput
                          control={control}
                          name="companyId"
                          label="Empresa"
                          size="sm"
                          onChange={(e) => {
                            setFilter((prev) => ({
                              ...prev,
                              companyId: e.value,
                            }));
                          }}
                          loadDataFn={({ value, cb }) =>
                            findAllCompanys({
                              filter: value,
                              limit: 10,
                              page: 0,
                            }).then((retorno) => {
                              if (retorno?.registers?.length > 0) {
                                cb([
                                  { label: "Escolher uma opção...", value: "" },
                                  ...retorno.registers?.map((d: any) => ({
                                    label: d.name,
                                    value: d.id,
                                  })),
                                ]);
                              } else {
                                cb([]);
                              }
                            })
                          }
                        />
                      )}

                    {filterable?.includes("customerId") && (
                      <FormRemoteSelectInput
                        control={control}
                        name="customer"
                        label="Cliente"
                        size="sm"
                        onChange={(e) => {
                          setFilter((prev) => ({
                            ...prev,
                            customerId: e.value,
                          }));
                        }}
                        loadDataFn={({ value, cb }) =>
                          findAllClients({
                            filter: value,
                            limit: 10,
                            page: 0,
                          }).then((retorno) => {
                            if (retorno?.registers?.length > 0) {
                              cb([
                                { label: "Escolher uma opção...", value: "" },
                                ...retorno.registers?.map((d: any) => ({
                                  label: d.name,
                                  value: d.id,
                                })),
                              ]);
                            } else {
                              cb([]);
                            }
                          })
                        }
                      />
                    )}

                    {filterable?.includes("promoterId") && (
                      <FormRemoteSelectInput
                        control={control}
                        name="promoter"
                        label="Promoter"
                        size="sm"
                        onChange={(e) => {
                          setFilter((prev) => ({
                            ...prev,
                            promoterId: e.value,
                          }));
                        }}
                        loadDataFn={({ value, cb }) =>
                          findAllClientsRecurrences({
                            filter: value,
                            limit: 10,
                            page: 0,
                          }).then((retorno) => {
                            if (retorno?.registers?.length > 0) {
                              cb([
                                { label: "Escolher uma opção...", value: "" },
                                ...retorno.registers?.map((d: any) => ({
                                  label: d.name,
                                  value: d.id,
                                })),
                              ]);
                            } else {
                              cb([]);
                            }
                          })
                        }
                      />
                    )}

                    {filterable?.includes("onlyCharges") && (
                      <SelectForm
                        control={control}
                        name="charges"
                        label="Cobranças"
                        options={[
                          {
                            label: "Selecione...",
                            value: "",
                          },
                          {
                            label: "Apenas Cobranças",
                            value: "onlyCharges",
                          },
                          {
                            label: "Sem Cobranças",
                            value: "noCharges",
                          },
                        ]}
                        onChange={(e) => {
                          setFilter((prev) => ({ ...prev, charges: e }));
                        }}
                      />
                    )}

                    {filterable?.includes("maquinetaId") && (
                      <FormRemoteSelectInput
                        control={control}
                        name="maquineta"
                        label="Terminal"
                        size="sm"
                        onChange={(e) => {
                          setFilter((prev) => ({
                            ...prev,
                            maquinetaId: e.value,
                          }));
                        }}
                        loadDataFn={({ value, cb }) =>
                          findAllTerminals({
                            filter: value,
                            limit: 10,
                            page: 0,
                            orderBy: [
                              {
                                id: "name",
                                desc: false,
                              },
                            ],
                          }).then((retorno) => {
                            if (retorno?.registers?.length > 0) {
                              cb([
                                { label: "Escolher uma opção...", value: "" },
                                ...retorno.registers?.map((d: any) => ({
                                  label: `${d.number} - ${d.name}`,
                                  value: d.id,
                                })),
                              ]);
                            } else {
                              cb([]);
                            }
                          })
                        }
                      />
                    )}
                    {exportPdf && (
                      <Button
                        leftIcon={<FaUpload />}
                        size="sm"
                        rounded="md"
                        width={"100%"}
                        variant="outline"
                        colorScheme="blue"
                        isLoading={loading}
                        onClick={() => exportToFile("pdf")}
                      >
                        Exportar PDF
                      </Button>
                    )}
                    {exportCsv && (
                      <Button
                        leftIcon={<FaUpload />}
                        size="sm"
                        borderRadius="sm"
                        isLoading={loading}
                        variant="outline"
                        width={"100%"}
                        colorScheme="blue"
                        rounded={"sm"}
                        onClick={() => exportToFile("csv")}
                      >
                        Exportar CSV
                      </Button>
                    )}
                  </VStack>
                </DrawerBody>

                <DrawerFooter>
                  <CustomButton onClick={onClose}>Fechar</CustomButton>
                  <Button
                    colorScheme="blue"
                    size={"sm"}
                    leftIcon={<PiFunnel />}
                    rounded={"md"}
                    ml={3}
                    onClick={() => {
                      setFilter({
                        pageIndex: 0,
                        pageSize: 20,
                        orderBy: [],
                        filter: "",
                        ...(filterable?.includes("createdAt") && {
                          initialDate: DateTime.now().startOf("month").toISO(),
                          finalDate: DateTime.now().endOf("month").toISO(),
                        }),
                      });
                      onClose();
                    }}
                  >
                    Limpar filtros
                  </Button>
                </DrawerFooter>
              </DrawerContent>
            </Drawer>
            {right}
          </HStack>
        </HStack>
        {data?.aggregate?.length && (
          <Grid
            templateColumns={{
              base: "repeat(1, 1fr)",
              sm: "repeat(1, 1fr)",
              md: "repeat(4, 1fr)",
              lg: "repeat(5, 1fr)",
            }}
            gap={6}
            px={2}
            mt={3}
          >
            {data?.aggregate?.map((a) => {
              return (
                <GridItem key={a.key}>
                  <MiniStatistics
                    name={BalanceTypeDescription[a.key]}
                    value={maskCurrency(Math.abs(+a.total))}
                  />
                </GridItem>
              );
            })}
          </Grid>
        )}{" "}
        <Divider mt={5} />
        {isLoading ? (
          <Flex
            justify="center"
            align="center"
            h="100%"
            w="100%"
            minH={{ sm: "100px", lg: "200px" }}
            color="gray.500"
          >
            <Text>Carregando...</Text>
          </Flex>
        ) : (
          <TableList
            actions={actions}
            columnsData={columnsData}
            itensData={data?.registers || []}
            pageCount={data?.pages || 1}
            setFilter={setFilter}
            enableSelect={enableSelect}
            setSelectedRows={setSelectedRows}
            aggregateSelection={aggregateSelection}
          />
        )}
      </Card>
    </>
  );
}
