// Chakra imports
import {
  Badge,
  Box,
  ButtonGroup,
  IconButton,
  SimpleGrid,
  Tooltip,
  useToast,
} from "@chakra-ui/react";

import { useMemo } from "react";
import { changeStatusCompanys, findAllCompanys } from "services/api.service";
import { Column } from "react-table";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";
import TableComponent from "components/TableComponent";
import { FaFile, FaLightbulb } from "react-icons/fa";
import CreateCompany from "./components/CreateCompany";
import { maskDocument } from "utils/formatters";
import { MdBlock } from "react-icons/md";
import { CheckIcon } from "@chakra-ui/icons";
import { queryClient } from "index";
import { IoPeople } from "react-icons/io5";
import { maskCurrency } from "utils/number";
import SendEmails from "./components/SendEmails";
import useAuth, { UserPermissions } from "contexts/useAuth";
import EditCompany from "./components/EditCompany";

export const StatusRender = (status: number) => {
  if (status === 0) return <Badge colorScheme="orange">Inativo</Badge>;
  if (status === 1) return <Badge colorScheme="green">Ativo</Badge>;
  if (status === 9) return <Badge colorScheme="red">Excluído</Badge>;
};

export default function Companys() {
  const toast = useToast();
  const history = useHistory();
  const { hasPermission } = useAuth();

  const hasPerm = hasPermission(UserPermissions.COMPANYS_WRITE);

  const columns = useMemo(
    () =>
      [
        {
          Header: "Nome da Empresa",
          accessor: "name",
        },
        {
          Header: "document",
          accessor: "document",
          Cell: ({ value }) => {
            return maskDocument(value);
          },
        },
        {
          Header: "Saldo",
          accessor: "balance",
          Cell: ({ value }) => {
            return maskCurrency(value);
          },
        },
        {
          Header: "Cliente Principal",
          accessor: "mainCustomerId",
          Cell: ({ value }) => {
            return value ? (
              <Badge colorScheme="green">OK</Badge>
            ) : (
              "Não configurado"
            );
          },
        },
        {
          Header: "Saldo Pendente",
          accessor: "balancePending",
          Cell: ({ value }) => {
            return maskCurrency(value);
          },
        },
        {
          Header: "Status",
          accessor: "status",
          Cell: ({ value }) => {
            return StatusRender(value);
          },
        },
        {
          accessor: "createdAt",
          Header: "Data de Cadastro",
          Cell: ({ value }) => {
            return DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm:ss");
          },
        },

        {
          Header: "Ações",
          accessor: "actions",
        },
      ] as Column[],
    []
  );

  const actions = (row: any) => (
    <ButtonGroup>
      {hasPerm && <EditCompany company={row} />}
      {hasPerm && <SendEmails companyId={row.id} />}
      <Tooltip label="Ver Clientes">
        <IconButton
          aria-label="Apertar"
          rounded="md"
          size="sm"
          variant="outline"
          icon={<IoPeople />}
          onClick={() => history.push(`/admin/customers?companyId=${row.id}`)}
        />
      </Tooltip>
      <Tooltip label="Ver Integrações">
        <IconButton
          aria-label="Apertar"
          rounded="md"
          size="sm"
          variant="outline"
          icon={<FaLightbulb />}
          onClick={() =>
            history.push(`/admin/integrations?companyId=${row.id}`)
          }
        />
      </Tooltip>
      <Tooltip label="Ver Planos">
        <IconButton
          aria-label="Apertar"
          rounded="md"
          size="sm"
          variant="outline"
          icon={<FaFile />}
          onClick={() => history.push(`/admin/plans?companyId=${row.id}`)}
        />
      </Tooltip>
      <Tooltip label="Desabilitar/Ativar Empresa">
        <IconButton
          aria-label="Apertar"
          rounded="md"
          size="sm"
          variant="outline"
          isDisabled={!hasPerm}
          icon={row.status === 1 ? <MdBlock /> : <CheckIcon />}
          onClick={() =>
            changeStatusCompanys({
              id: row.id,
              status: row.status === 1 ? 0 : 1,
            })
              .then(() => {
                queryClient.refetchQueries({
                  queryKey: ["findAllCompanys"],
                  type: "active",
                });
              })
              .catch((err) => {
                toast({
                  title: "Erro ao alterar status da empresa",
                  description: err.message,
                  status: "error",
                  duration: 9000,
                  isClosable: true,
                });
              })
          }
        />
      </Tooltip>
    </ButtonGroup>
  );

  // Chakra Color Mode
  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <TableComponent
          columnsData={columns}
          // filterable={["customerId"]}
          right={
            <>
              {hasPerm && <CreateCompany />}
              {hasPerm && <SendEmails />}
            </>
          }
          queryFn={findAllCompanys}
          queryKey="findAllCompanys"
          actions={actions}
        />
      </SimpleGrid>
    </Box>
  );
}
