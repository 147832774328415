// Chakra imports
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  HStack,
  SimpleGrid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import { findEyeTransaction } from "services/api.service";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { DateTime } from "luxon";
import { maskCurrency } from "utils/number";
import LinkToPage from "components/LinkToPage";
import useAuth, { UserPermissions } from "contexts/useAuth";
import { MovementEye, ProductEye } from "./Eye.type";

const BalancesForMovement: React.FC<{
  products?: ProductEye[];
}> = ({ products }) => {
  return (
    <TableContainer>
      <Table variant="striped" size="sm">
        <Thead>
          <Tr>
            <Th>Produto</Th>
            <Th>Cliente Split</Th>
            <Th isNumeric>Valor</Th>
            <Th isNumeric>Valor Split</Th>
            <Th>Data registro</Th>
          </Tr>
        </Thead>
        <Tbody>
          {products?.map((item) => (
            <Tr key={item.id}>
              <Td>{item.name}</Td>
              <Td>
                <LinkToPage
                  href={`/#/admin/customer/${item?.customerId}`}
                  name={item.customer?.name}
                />
              </Td>
              <Td isNumeric> {maskCurrency(item?.amount)}</Td>
              <Td isNumeric> {maskCurrency(item?.splitedAmount)}</Td>
              <Td>
                <HStack>
                  <Box mr={2}>
                    {item?.createdAt
                      ? DateTime.fromISO(item?.createdAt).toFormat(
                          "dd/MM/yyyy HH:mm"
                        )
                      : "---"}
                  </Box>
                </HStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const Movimentos: React.FC<{
  movements?: MovementEye[];
}> = ({ movements }) => {
  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={4}>
      {movements?.map((item) => (
        <Box key={item.id}>
          <LinkToPage
            href={`/#/admin/movement/${item?.id}`}
            name={item.transactionCode}
          />
        </Box>
      ))}
    </Grid>
  );
};

export default function TransactionEye() {
  const { id } = useParams<{ id: string }>();

  const { hasPermission } = useAuth();
  const hasPerm = hasPermission(UserPermissions.MOVEMENTS_WRITE);

  const { data } = useQuery(["getEyeTransaction", id], () =>
    findEyeTransaction(id)
  );

  const itens = useMemo(
    () => [
      {
        title: "Id da transação",
        content: data?.transactionId,
      },
      {
        title: "Nome do cliente",
        content: data?.customer ? (
          <LinkToPage
            href={`/#/admin/customer/${data?.customer.id}`}
            name={data.customer?.name}
          />
        ) : (
          <Text>Não encontrado</Text>
        ),
      },
      {
        title: "Data da transação",
        content: DateTime.fromISO(data?.createdAt).toFormat(
          `dd/MM/yyyy - HH:mm:ss`
        ),
      },
      {
        title: "Valor Bruto",
        content: maskCurrency(data?.amount),
      },
      {
        title: "Tipo operação",
        content: "Venda eye",
      },
    ],
    [data, hasPerm]
  );

  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <Card px={3}>
          <CardHeader p={2} mt={3}>
            <Box fontSize="2xl" fontWeight="semibold">
              Detalhes da transação
            </Box>
            <small>Transação: #{data?.id}</small>
          </CardHeader>
          <SimpleGrid
            mb="20px"
            columns={{ sm: 1, md: 2, lg: 3, xl: 4 }}
            spacing={5}
          >
            {itens.map((item) => (
              <Box key={item.title} boxShadow="none" p="2" px={2}>
                <Box fontSize="md" fontWeight="semibold" color="gray.600">
                  {item.title}
                </Box>
                <Box fontSize="md">{item.content}</Box>
              </Box>
            ))}
          </SimpleGrid>
          <Divider color="gray.300" />
          <Box mb={4}>
            <HStack
              mb={2}
              p="1"
              px={2}
              fontSize="lg"
              fontWeight="semibold"
              color="gray.600"
              flexDirection={{ base: "column", md: "row" }}
            >
              <Text>Detalhes dos produtos</Text>
            </HStack>

            <BalancesForMovement products={data?.products} />
          </Box>{" "}
          <Box mb={4}>
            <HStack
              mb={2}
              p="1"
              px={2}
              fontSize="lg"
              fontWeight="semibold"
              color="gray.600"
              flexDirection={{ base: "column", md: "row" }}
            >
              <Text>Detalhes dos movimentos</Text>
            </HStack>
            <Movimentos movements={data?.movements} />
          </Box>
        </Card>
      </SimpleGrid>
    </Box>
  );
}
