import { CheckIcon } from "@chakra-ui/icons";
import {
  Badge,
  ButtonGroup,
  Divider,
  Heading,
  IconButton,
  SimpleGrid,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import Card from "components/card/Card";
import TableComponent from "components/TableComponent";
import { queryClient } from "index";
import { DateTime } from "luxon";
import { useCallback, useMemo } from "react";
import { MdBlock } from "react-icons/md";
import { useParams } from "react-router-dom";
import {
  changeStatusLock,
  findAllClientsLocks,
  findCustomerLocks,
} from "services/api.service";
import { FreezeMethods } from "types/Customers";
import { maskCurrency } from "utils/number";
import BlockBalance from "./BlockBalance";
import LockFixed from "./LockFixed";
import ReleaseBlockBalances from "./ReleaseBlockBalances";
import useAuth, { UserPermissions } from "contexts/useAuth";
import { OriginTaxesDescription } from "types/Fees";
import EditBlockBalance from "./EditBlockBalance";
import LinkToPage from "components/LinkToPage";

export default function BlocksTab() {
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const { data, refetch } = useQuery(["customerLocks", id], () =>
    findCustomerLocks(id)
  );

  const { hasPermission } = useAuth();
  const writePermission = hasPermission(UserPermissions.CUSTOMERS_WRITE);

  const columns = useMemo(
    () => [
      {
        Header: "Quem Recebe",
        accessor: "CustomerReceiver.name",
        Cell: ({ value, row }: any) => {
          return value ? (
            <LinkToPage
              href={`/#/admin/customer/${row.original?.customerReceiverId}`}
              name={value}
              isExternal
            />
          ) : (
            "---"
          );
        },
      },
      {
        Header: "Tipo",
        align: "right",
        accessor: "type",
        Cell: ({ value }: any) => {
          return value === "FIXED" ? "Fixa" : "Percentual";
        },
      },
      {
        Header: "Método",
        align: "right",
        accessor: "method",
        Cell: ({ value }: any) => {
          return value === "SPLIT" ? "Split" : "Bloqueio";
        },
      },
      {
        Header: "Origem",
        align: "right",
        accessor: "origin",
        Cell: ({ value }: any) => {
          return OriginTaxesDescription[value];
        },
      },
      {
        Header: "Valor",
        accessor: "amount",
        Cell: ({ value, row }: any) => {
          if (row.original?.type === "PERCENTUAL") {
            return `${value} %`;
          }

          return maskCurrency(value);
        },
      },
      {
        Header: "Meta",
        accessor: "amountQuantity",
        Cell: ({ value }: any) => maskCurrency(+value),
      },

      {
        Header: "Já Bloqueado",
        accessor: "amountLocked",
        Cell: ({ value }: any) => maskCurrency(+value),
      },

      {
        accessor: "initialDate",
        Header: "Data de início",
        Cell: ({ value }) => {
          return DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm:ss");
        },
      },
      {
        accessor: "finalDate",
        Header: "Data fim",
        Cell: ({ value }) => {
          return DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm:ss");
        },
      },
      {
        Header: "Status",
        accessor: "locked",
        Cell: ({ value }: any) => {
          if (value) return <Badge colorScheme="green">Ativo</Badge>;
          else return <Badge colorScheme="red">Inativo</Badge>;
        },
      },
      {
        Header: "Ações",
        accessor: "actions",
      },
    ],
    []
  );

  const actions = useCallback(
    (row: any) => {
      return (
        <ButtonGroup>
          <EditBlockBalance editData={row} refetch={refetch} />
          <Tooltip label="Ativar/Desativar Bloqueio">
            <IconButton
              aria-label="Ver Dado"
              rounded="md"
              size="sm"
              variant="outline"
              icon={row.status ? <MdBlock /> : <CheckIcon />}
              onClick={() => {
                changeStatusLock(row.id, !row.locked)
                  .then(() => {
                    queryClient.refetchQueries({
                      queryKey: ["findAllClientsLocks"],
                      type: "active",
                    });

                    toast({
                      title: "Bloqueio alterado com sucesso",
                      status: "success",
                      duration: 3000,
                      isClosable: true,
                    });
                  })
                  .catch(() => {
                    toast({
                      title: "Erro ao alterar bloqueio",
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                    });
                  });
              }}
            />
          </Tooltip>
        </ButtonGroup>
      );
    },
    [toast]
  );

  return (
    <>
      <Card
        flexDirection="column"
        w="100%"
        px={5}
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Heading mb={5} fontWeight="semibold" fontSize="lg">
          Bloqueios
        </Heading>
        <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} gap={3}>
          {data && (
            <>
              <ReleaseBlockBalances
                customerId={id}
                data={data}
                refetch={refetch}
                disabled={!writePermission}
                description="Cartão de Crédito"
                type={FreezeMethods.CREDIT_CARD}
              />
              <ReleaseBlockBalances
                customerId={id}
                data={data}
                refetch={refetch}
                disabled={!writePermission}
                description="Cartão de Débito"
                type={FreezeMethods.DEBIT_CARD}
              />
              <ReleaseBlockBalances
                customerId={id}
                data={data}
                refetch={refetch}
                disabled={!writePermission}
                description="Pix"
                type={FreezeMethods.PIX}
              />
              <ReleaseBlockBalances
                customerId={id}
                data={data}
                disabled={!writePermission}
                refetch={refetch}
                description="Saque"
                type={FreezeMethods.WITHDRAW}
              />
              <ReleaseBlockBalances
                customerId={id}
                disabled={!writePermission}
                data={data}
                refetch={refetch}
                description="por garantia"
                type={FreezeMethods.LOCK}
              />
            </>
          )}
        </SimpleGrid>

        <Divider />

        <ButtonGroup mt={5}>
          {writePermission && (
            <>
              <BlockBalance refetch={refetch} customerId={id} />
              <LockFixed refetch={refetch} customerId={id} />
            </>
          )}
        </ButtonGroup>

        <TableComponent
          columnsData={columns}
          queryFn={(qb: any) => findAllClientsLocks({ ...qb, customerId: id })}
          queryKey="findAllClientsLocks"
          actions={actions}
          right={
            writePermission && (
              <BlockBalance refetch={refetch} customerId={id} />
            )
          }
        />
      </Card>
    </>
  );
}
