import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  HStack,
  Button,
  VStack,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import FormRemoteSelectInput, {
  SelectOptionType,
} from "components/FormRemoteSelectInput";
import { queryClient } from "index";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { MdChangeCircle } from "react-icons/md";
import {
  changeManyTerminals,
  findAllClients,
} from "services/api.service";

const LabelItem: FC<{
  label: string;
  value: string;
}> = ({ label, value }) => {
  return (
    <VStack mb={3} width="100%" alignItems="start">
      <Text as={"small"} fontSize="sm" color="gray.600">
        {label}
      </Text>
      <Text mt={"0px !important"} fontSize="md">
        {value || "---"}
      </Text>
    </VStack>
  );
};

const ChangeManyTerminals: FC<{
  ids: string[];
}> = ({ ids }) => {
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit } = useForm<{ customer: SelectOptionType }>({});

  const save = async (dataForm: { customer: SelectOptionType }) => {
    setLoading(true);
    changeManyTerminals({
      customerId: dataForm.customer.value,
      maquinetas: ids,
    })
      .then(() => {
        onClose();
        queryClient.refetchQueries({
          queryKey: ["findAllTerminals"],
          type: "active",
        });
        toast({
          title: "Sucesso",
          description: "Vínculo alterado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoading(false);
        toast({
          title: "Erro",
          description:
            err?.response?.data?.message || "Erro ao alterar vínculo",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Button
        onClick={()=>{
          onOpen()
          setLoading(false)
        }}
        size="sm"
        rounded="sm"
        variant="outline"
        colorScheme="brand"
      >
        <MdChangeCircle></MdChangeCircle>
        <Text ml={2}>Alterar</Text>
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Alterar Terminal em Massa</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack mb={5} width="100%" alignItems="start">
                <LabelItem label="Alterando" value={`${ids.length} Maquinas`} />
              </VStack>
              <FormRemoteSelectInput
                control={control}
                label="Cliente"
                required
                registerOptions={{ required: "Esse campo é obrigatório" }}
                loadDataFn={({ value, cb }) =>
                  findAllClients({ filter: value }).then((retorno) => {
                    if (retorno?.registers?.length > 0) {
                      cb(
                        retorno.registers?.map((d: any) => ({
                          label: d.name,
                          value: d.id,
                        }))
                      );
                    } else {
                      cb([]);
                    }
                  })
                }
                name="customer"
              />
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(save)}
                >
                  Salvar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default ChangeManyTerminals;
