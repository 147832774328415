import { CheckIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import React, { useMemo } from "react";
import { MdBlock } from "react-icons/md";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { ativeUserService, deleteUserService } from "services/api.service";
import EditUser from "./EditUser";
import RegisterUser from "./RegisterUser";
import SeeGroups from "./SeeGroups";
import useAuth, { UserPermissions } from "contexts/useAuth";
import CustomButton from "components/CustomButton";
import { FaPlus } from "react-icons/fa";

export default function CustomersTable(props: {
  columnsData: any;
  tableData: any;
  refetch: any;
}) {
  const { columnsData, tableData, refetch } = props;

  const toast = useToast();

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenNewUser,
    onOpen: onOpenNewUser,
    onClose: onCloseNewUser,
  } = useDisclosure();
  const [user, setUser] = React.useState<any>(null);
  const cancelRef = React.useRef();
  const { hasPermission } = useAuth();
  const writePermission = hasPermission(UserPermissions.USERS_WRITE);

  const deleteUser = () => {
    deleteUserService(user.id).then(() => {
      refetch();
      onClose();
      toast({
        title: "Usuário deletado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    });
  };

  const changeAtiveUser = (id: string, ative: boolean) => {
    ativeUserService(id, !ative).then(() => {
      refetch();
      toast({
        title: "Usuário alterado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    });
  };

  const tableInstance = useTable<{
    id: string;
    ative: boolean;
    name: string;
    email: string;
  }>(
    {
      columns,
      data,
      pageCount: 0,
      initialState: {
        pageSize: 1000,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  return (
    <Card flexDirection="column" w="100%" px="0px">
      {isOpenNewUser && (
        <RegisterUser
          isOpen={isOpenNewUser}
          onClose={() => {
            onCloseNewUser();
            refetch();
          }}
        ></RegisterUser>
      )}
      {isOpen && (
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Deletar usuário
              </AlertDialogHeader>

              <AlertDialogBody>
                Tem certeza que deseja deletar esse usuário? Essa ação não pode
                ser desfeita.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancelar
                </Button>
                <Button colorScheme="red" onClick={deleteUser} ml={3}>
                  Deletar
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
      <Box textAlign={"end"} mr={2}>
        <ButtonGroup>
          {writePermission && <SeeGroups />}
          {writePermission && (
            <CustomButton
              isDisabled={false}
              colorScheme="brand"
              variant="solid"
              leftIcon={<FaPlus />}
              onClick={onOpenNewUser}
            >
              Adicionar
            </CustomButton>
          )}
        </ButtonGroup>
      </Box>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    textAlign={column.id === "actions" ? "center" : "left"}
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()} overflow="scroll">
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data;
                  if (cell.column.id === "actions") {
                    data = (
                      <ButtonGroup>
                        {writePermission && (
                          <EditUser data={cell.row.original} />
                        )}
                        {writePermission && (
                          <IconButton
                            aria-label="Alterar status do usuário"
                            rounded="md"
                            size="sm"
                            variant="outline"
                            icon={
                              cell.row.original.ative ? (
                                <MdBlock />
                              ) : (
                                <CheckIcon />
                              )
                            }
                            onClick={() => {
                              changeAtiveUser(
                                cell.row.original.id,
                                cell.row.original.ative
                              );
                            }}
                          />
                        )}
                        {writePermission && (
                          <IconButton
                            aria-label="Alterar status do usuário"
                            rounded="md"
                            size="sm"
                            variant="outline"
                            icon={<DeleteIcon />}
                            onClick={() => {
                              setUser(cell.row.original);
                              onOpen();
                            }}
                          />
                        )}
                      </ButtonGroup>
                    );
                  } else if (cell.column.id === "ative") {
                    if (cell.value)
                      data = <Badge colorScheme="green">Ativo</Badge>;
                    else data = <Badge colorScheme="red">Inativo</Badge>;
                  } else {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                  }

                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor="transparent"
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}
