import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  HStack,
  Button,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import CustomButton from "components/CustomButton";
import FormRemoteSelectInput, {
  SelectOptionType,
} from "components/FormRemoteSelectInput";
import { queryClient } from "index";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { FaPen } from "react-icons/fa";
import { addOnboardingToCompany, findAllCompanys } from "services/api.service";

const RegisterOnboardingCompany: FC<{ onboardingId: string }> = ({
  onboardingId,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit } = useForm<{
    company: SelectOptionType;
  }>();

  const save = async (dataForm: { company: SelectOptionType }) => {
    setLoading(true);
    addOnboardingToCompany(onboardingId, dataForm?.company?.value)
      .then(() => {
        queryClient.refetchQueries({
          queryKey: ["onboarding"],
          type: "active",
        });
        onClose();
        toast({
          title: "Sucesso",
          description: "Onboarding vinculado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Erro",
          description:
            err?.response?.data?.message || "Erro ao alterar vínculo",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <CustomButton
        onClick={onOpen}
        variant="outline"
        colorScheme="brand"
        leftIcon={<FaPen />}
      >
        Vincular à empresa
      </CustomButton>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Repassar onboarding à Empresa</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormRemoteSelectInput
                control={control}
                label="Empresa"
                loadDataFn={({ value, cb }) =>
                  findAllCompanys({ filter: value, limit: 20, page: 0 }).then(
                    (retorno) => {
                      if (retorno?.registers?.length > 0) {
                        cb(
                          retorno.registers?.map((d: any) => ({
                            label: d.name,
                            value: d.id,
                          }))
                        );
                      } else {
                        cb([]);
                      }
                    }
                  )
                }
                name="company"
              />
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(save)}
                >
                  Adicionar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default RegisterOnboardingCompany;
