import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import CustomButton from "components/CustomButton";
import { useTwoFaHelper } from "contexts/TwoFaCheckContext";
import React from "react";
import { FaLock } from "react-icons/fa";
import { resetMasterPassword } from "services/api.service";

const ReserMasterPassword: React.FC<{
  customerId: string;
}> = ({ customerId }) => {
  const {
    onOpen: onOpenChangePassword,
    isOpen: isOpenChangePassword,
    onClose: onCloseChangePass,
  } = useDisclosure();

  const toast = useToast();

  const onClose = () => {
    onCloseChangePass();
  };

  const { requestTwoFa } = useTwoFaHelper();

  const onSubmit = async () => {
    const token = await requestTwoFa();
    if (!token) return;
    let request = resetMasterPassword(customerId, token?.id);

    request
      .then(() => {
        toast({
          title: "Succeso",
          description: `Senha Resetada com Sucesso`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose();
      })
      .catch((err) => {
        toast({
          title: "Error",
          description: err.response.data.message,
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Button
        onClick={onOpenChangePassword}
        size="sm"
        rounded="sm"
        variant="outline"
        colorScheme="brand"
      >
        <FaLock />
        <Text ml={2}>Resetar Senha Master</Text>
      </Button>
      {isOpenChangePassword && (
        <Portal>
          <Modal isOpen={isOpenChangePassword} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Resetar Senha Master</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text>
                  Ao resetar a senha master, o cliente não terá mais a segurança
                  ao realizar as transações sendo necessário reconfigurar a
                  senha mestre.
                </Text>
              </ModalBody>

              <ModalFooter>
                <CustomButton onClick={onSubmit}>
                  Resetar Senha Master
                </CustomButton>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Portal>
      )}
    </>
  );
};

export default ReserMasterPassword;
