import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  IconButton,
  Tooltip,
  useToast,
  HStack,
  Card,
  CardHeader,
  CardBody,
  Text,
  Grid,
  CardFooter,
  Skeleton,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import CustomButton from "components/CustomButton";
import React from "react";
import { FaDraftingCompass, FaTrash } from "react-icons/fa";
import { findAllEyePoints, removeLinkEye } from "./api";
import ShowEyeClients from "./ShowEyeClients";
import ConfigureEyeIntegration from "./ConfigureEyeIntegration";

export interface Company {
  id: string;
  name: string;
  document: string;
  status: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IntegrationResponse {
  id: string;
  name: string;
  type: string;
  user: string;
  createdAt: Date;
  identifierUrl: string;
  active: boolean;
}

const EyeMobileIntegration: React.FC<{ data: IntegrationResponse }> = ({
  data,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [showInactives, setShowInactives] = React.useState(false);

  const {
    data: points,
    refetch,
    isLoading,
  } = useQuery(
    [data.id, "points"],
    () => findAllEyePoints({ integrationId: data.id, limit: 10, page: 0 }),
    {
      enabled: isOpen,
    }
  );

  const removeLink = (id: number) => {
    removeLinkEye(id)
      .then(() => {
        refetch();
        toast({
          title: "Link removido com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Erro ao remover link",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      {data.type === "EYE_MOBILE" && (
        <Tooltip label="Configurar Eye Mobile">
          <IconButton
            aria-label="Apertar"
            rounded="md"
            isDisabled={!data.identifierUrl}
            size="sm"
            variant="outline"
            icon={<FaDraftingCompass />}
            onClick={onOpen}
          />
        </Tooltip>
      )}
      {isOpen && (
        <Modal
          size={{
            base: "full",
            md: "3xl",
          }}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Eye Mobile </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <HStack my={3} spacing={3}>
                <CustomButton
                  colorScheme="red"
                  onClick={() => {
                    setShowInactives(!showInactives);
                  }}
                >
                  Mostrar inativos
                </CustomButton>
              </HStack>
              <Grid templateColumns="repeat(3, 1fr)" gap={3}>
                {isLoading
                  ? [...Array(10)].map((_, index) => (
                      <Skeleton key={index} height={200} />
                    ))
                  : points
                      ?.filter((point) => {
                        if (showInactives) return true;
                        return point.status === 1;
                      })
                      .map((point) => (
                        <Card
                          key={point.id}
                          bg={point.links ? "green.100" : "white"}
                        >
                          <CardHeader>
                            <Text fontSize="sm" color="gray.500">
                              #{point.id}
                            </Text>
                            <Text fontWeight="bold">{point.name}</Text>
                          </CardHeader>

                          <CardBody>
                            <Text fontSize="sm" color="gray.500">
                              Menu:{" "}
                              <Text fontWeight="bold" color="blue.500">
                                {point.menu?.name}
                              </Text>
                            </Text>
                            <Text fontSize="sm" color="gray.500">
                              Status Webhook:{" "}
                              <Text fontWeight="bold" color="blue.500">
                                {point.links?.webhookId ? "Ativo" : "Inativo"}
                              </Text>
                            </Text>
                            <Text fontSize="sm" color="gray.500">
                              Cliente:{" "}
                              <Text fontWeight="bold" color="blue.500">
                                {point.links?.customer?.name}
                              </Text>
                            </Text>
                          </CardBody>

                          <CardFooter>
                            {!point.links ? (
                              <ConfigureEyeIntegration
                                integrationId={data.id}
                                refetch={refetch}
                                point={point}
                                menu={point.menu}
                              />
                            ) : (
                              <HStack justify="space-between">
                                <ShowEyeClients
                                  eyeIntegrationId={point.links.id}
                                  integrationId={data.id}
                                  menuId={+point.menu?.id}
                                />
                                <IconButton
                                  aria-label="Remover"
                                  size="sm"
                                  variant="outline"
                                  rounded="md"
                                  isDisabled={point.status === 0}
                                  onClick={() => removeLink(+point.links.id)}
                                  colorScheme="red"
                                  icon={<FaTrash />}
                                />
                              </HStack>
                            )}
                          </CardFooter>
                        </Card>
                      ))}
              </Grid>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default EyeMobileIntegration;
