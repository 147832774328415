// Chakra imports
import {
  Badge,
  Box,
  ButtonGroup,
  IconButton,
  SimpleGrid,
  Tooltip,
} from "@chakra-ui/react";

import { useMemo } from "react";
import { findAllOnboardings } from "services/api.service";
import { Column } from "react-table";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";
import TableComponent from "components/TableComponent";
import { maskDocument } from "utils/formatters";
import { IoEye } from "react-icons/io5";

export enum OnboardingStatus {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  REJECTED = "REJECTED",
  APPROVED = "APPROVED",
}

export const OnboardingStatusRender: React.FC<{ status: OnboardingStatus }> = ({
  status,
}) => {
  if (status === OnboardingStatus.COMPLETED)
    return <Badge colorScheme="orange">Aguardando</Badge>;
  if (status === OnboardingStatus.APPROVED)
    return <Badge colorScheme="green">Aprovado</Badge>;
  if (status === OnboardingStatus.REJECTED)
    return <Badge colorScheme="red">Recusado</Badge>;
};

export default function Onboardings() {
  const history = useHistory();

  const columns = useMemo(
    () =>
      [
        {
          Header: "Empresa",
          accessor: "company.name",
          Cell: ({ value }) => {
            return value ?? "---";
          },
        },
        {
          Header: "Nome",
          accessor: "name",
        },
        {
          Header: "document",
          accessor: "document",
          Cell: ({ value }) => {
            return maskDocument(value);
          },
        },
        {
          Header: "Email",
          accessor: "email",
          Cell: ({ value }) => {
            return value;
          },
        },
        {
          Header: "Status",
          accessor: "status",
          Cell: ({ value }) => {
            return <OnboardingStatusRender status={value} />;
          },
        },
        {
          accessor: "createdAt",
          Header: "Data de Cadastro",
          Cell: ({ value }) => {
            return DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm:ss");
          },
        },

        {
          Header: "Ações",
          accessor: "actions",
        },
      ] as Column[],
    []
  );

  const actions = (row: any) => (
    <ButtonGroup>
      <Tooltip label="Ver onboarding" aria-label="Ver onboarding">
        <IconButton
          aria-label="Apertar"
          rounded="md"
          size="sm"
          variant="outline"
          icon={<IoEye />}
          onClick={() => history.push(`/admin/onboarding/${row.id}`)}
        />
      </Tooltip>
    </ButtonGroup>
  );

  // Chakra Color Mode
  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <TableComponent
          columnsData={columns}
          // filterable={["customerId"]}
          right={<></>}
          queryFn={findAllOnboardings}
          queryKey="findAllOnboardings"
          actions={actions}
        />
      </SimpleGrid>
    </Box>
  );
}
