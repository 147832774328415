import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Select,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import FormLabelCustom from "components/FormLabel";
import FormRemoteSelectInput, {
  SelectOptionType,
} from "components/FormRemoteSelectInput";
import InputForm from "components/InputForm";
import InputNumber from "components/InputNumber";
import PercentageNumberInput from "components/PercentageInput";
import { queryClient } from "index";
import { DateTime } from "luxon";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaPen } from "react-icons/fa";
import { editBlockAmount, findAllClients } from "services/api.service";
import { OriginTaxesDescription, TaxesOriginType, TaxesType } from "types/Fees";
export interface ChangePasswordCustomerForm {
  amount: number;
  amountQuantity: number;
  type: TaxesType;
  initialDate: string;
  method: "LOCKS" | "SPLIT";
  origin: TaxesOriginType;
  finalDate: string;
  customerReceiverId: SelectOptionType;
}

interface ResponseBlockBalance {
  amount: string;
  amountLocked: string;
  amountQuantity: string;
  customerId: string;
  customerReceiverId: null;
  finalDate: string;
  initialDate: string;
  type: string;
  method: string;
  origin: null;
  locked: boolean;
  id: string;
  Customer: {
    name: string;
    id: string;
  };
  CustomerReceiver: {
    name: string;
    id: string;
  };
}

const EditBlockBalance: React.FC<{
  editData: ResponseBlockBalance;
  refetch: () => void;
}> = ({ editData, refetch }) => {
  const {
    onOpen: onOpenChangePassword,
    isOpen,
    onClose: onCloseChangePass,
  } = useDisclosure();
  const [typeTax, setTypeTax] = React.useState<TaxesType>(TaxesType.PERCENTUAL);
  const [method, setMethod] = React.useState<"LOCKS" | "SPLIT">("SPLIT");

  const [loading, setLoading] = React.useState(false);

  const toast = useToast();

  const { control, reset, handleSubmit, register, setValue } =
    useForm<ChangePasswordCustomerForm>({
      defaultValues: {
        amount: 0,
        method: "SPLIT",
      },
    });

  const onClose = () => {
    onCloseChangePass();
    reset();
  };

  const onSubmit = (dataRequest: ChangePasswordCustomerForm) => {
    if (dataRequest.method === "SPLIT" && !dataRequest.customerReceiverId) {
      toast({
        title: "Error",
        description: "O recebedor é obrigatório",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    let request = editBlockAmount(editData.id, {
      ...dataRequest,
      customerReceiverId: dataRequest.customerReceiverId?.value,
      customerId: editData.customerId,
    });
    setLoading(true);
    request
      .then(() => {
        refetch();
        queryClient.refetchQueries({
          queryKey: ["findAllClientsLocks"],
          type: "active",
        });

        toast({
          title: "Succeso",
          description: `Bloqueio realizado com Sucesso`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose();
      })
      .catch((err) => {
        toast({
          title: "Error",
          description: err?.response?.data?.message,
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (isOpen) {
      setValue("type", editData.type as any);
      setValue("method", editData.method as any);
      setValue("amount", +editData.amount);
      setValue("amountQuantity", +editData.amountQuantity);
      setValue(
        "initialDate",
        DateTime.fromISO(editData.initialDate)
          .setZone("America/Sao_Paulo")
          .toFormat("yyyy-MM-dd'T'HH:mm")
      );
      setValue(
        "finalDate",
        DateTime.fromISO(editData.finalDate)
          .setZone("America/Sao_Paulo")
          .toFormat("yyyy-MM-dd'T'HH:mm")
      );
      setValue("origin", editData.origin as any);
      if (editData.customerReceiverId) {
        setValue("customerReceiverId", {
          value: editData.customerReceiverId,
          label: editData.CustomerReceiver?.name,
        });
      }
      setMethod(editData.method as any);
      setTypeTax(editData.type as any);
    }
  }, [editData, isOpen]);

  return (
    <>
      <IconButton
        onClick={onOpenChangePassword}
        size="sm"
        aria-label="Editar"
        rounded="md"
        variant="outline"
        colorScheme="brand"
        icon={<FaPen />}
      />
      {isOpen && (
        <Portal>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Bloqueio de valores</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <VStack spacing={5}>
                    <FormControl>
                      <FormLabelCustom name="type" label="Tipo da taxa" />
                      <Select
                        placeholder="Selecione o tipo da taxa."
                        {...register("type", { required: true })}
                        onChange={(e) => {
                          setValue("type", e.target.value as any);
                          setTypeTax(e.target.value as TaxesType);
                        }}
                      >
                        <option value="FIXED">FIXA</option>
                        <option value="PERCENTUAL">PERCENTUAL</option>
                      </Select>
                    </FormControl>
                    {typeTax === "PERCENTUAL" ? (
                      <PercentageNumberInput
                        control={control}
                        label="Valor de bloqueio"
                        rules={{
                          required: "Campo obrigatório",
                        }}
                        name="amount"
                      />
                    ) : (
                      <InputNumber
                        control={control}
                        label="Valor de bloqueio"
                        name="amount"
                        rules={{
                          required: "Campo obrigatório",
                        }}
                      />
                    )}

                    <FormControl>
                      <FormLabelCustom name="method" label="Método" />
                      <Select
                        placeholder="Selecione o método."
                        {...register("method", { required: true })}
                        onChange={(e) => {
                          setValue("method", e.target.value as any);
                          setMethod(e.target.value as any);
                        }}
                      >
                        <option value="LOCKS">Bloqueio</option>
                        <option value="SPLIT">Splitagem</option>
                      </Select>
                      <FormHelperText>
                        Caso seja splitagem, o valor será dividido entre os
                        clientes. Nesse caso o recebedor deve ser informado.
                      </FormHelperText>
                    </FormControl>

                    {method === "SPLIT" && (
                      <>
                        <FormRemoteSelectInput
                          control={control}
                          required
                          label="Recebedor splitagem"
                          loadDataFn={({ value, cb }) =>
                            findAllClients({ filter: value }).then(
                              (retorno) => {
                                if (retorno?.registers?.length > 0) {
                                  cb(
                                    retorno.registers?.map((d: any) => ({
                                      label: d.name,
                                      value: d.id,
                                    }))
                                  );
                                } else {
                                  cb([]);
                                }
                              }
                            )
                          }
                          name="customerReceiverId"
                        />
                        <FormControl>
                          <FormLabelCustom name="origin" label="Origem" />
                          <Select
                            placeholder="Origem da taxa..."
                            {...register("origin", { required: false })}
                            onChange={(e) => {
                              setValue("origin", e.target.value as any);
                            }}
                          >
                            {Object.keys(OriginTaxesDescription).map((key) => (
                              <option key={key} value={key}>
                                {OriginTaxesDescription[key]}
                              </option>
                            ))}
                          </Select>
                          <FormHelperText>
                            Campo para informar splitagem por tipo de taxa. Caso
                            não seja informado, será considerado o valor em
                            todos as origems.
                          </FormHelperText>
                        </FormControl>
                      </>
                    )}
                    <InputNumber
                      control={control}
                      name="amountQuantity"
                      label="Valor máximo bloqueado"
                      placeholder={"Valor máximo bloqueado"}
                    />
                    <InputForm
                      control={control}
                      name="initialDate"
                      label="Data inicial"
                      rules={{
                        required: "Campo obrigatório",
                      }}
                      type="datetime-local"
                    />
                    <InputForm
                      control={control}
                      name="finalDate"
                      label="Data final"
                      rules={{
                        required: "Campo obrigatório",
                      }}
                      type="datetime-local"
                    />
                    <Button
                      isLoading={loading}
                      type="submit"
                      variant={"outline"}
                      w={`100%`}
                      color={"green"}
                    >
                      Salvar
                    </Button>
                  </VStack>
                </form>
              </ModalBody>

              <ModalFooter></ModalFooter>
            </ModalContent>
          </Modal>
        </Portal>
      )}
    </>
  );
};

export default EditBlockBalance;
