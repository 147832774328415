import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  VStack,
  Tooltip,
  useToast,
  ModalFooter,
  Box,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { addClientEye } from "./api";
import CustomButton from "components/CustomButton";
import { useForm } from "react-hook-form";
import FormRemoteSelectInput, {
  SelectOptionType,
} from "components/FormRemoteSelectInput";
import { findAllClients } from "services/api.service";
import { useMutation } from "@tanstack/react-query";
import PercentageNumberInput from "components/PercentageInput";

export interface Company {
  id: string;
  name: string;
  document: string;
  status: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IntegrationResponse {
  id: string;
  name: string;
  type: string;
  user: string;
  createdAt: Date;
  identifierUrl: string;
  active: boolean;
}

const AddSplitEye: React.FC<{
  integrationId: string;
  eyeIntegrationId: number;
  refetch: () => void;
  group: SelectOptionType;
  editData?: {
    id: number;
    customer: SelectOptionType;
    splitPercentage: number;
  };
}> = ({ eyeIntegrationId, refetch, editData, group }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { control, setValue, handleSubmit } = useForm<{
    customer: SelectOptionType;
    splitPercentage: number;
  }>();

  const { mutateAsync, isLoading } = useMutation(
    (data: { customer: SelectOptionType; splitPercentage: number }) =>
      addClientEye({
        splitPercentage: data.splitPercentage,
        customerId: data.customer.value,
        productGroupId: +group.value,
        productName: group.label,
        eyeIntegrationId,
        id: editData?.id,
      })
  );

  const sendClientEye = async (data: {
    customer: SelectOptionType;
    splitPercentage: number;
  }) => {
    mutateAsync(data)
      .then(() => {
        toast({
          title: "Cliente atualizado com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose();
        refetch();
      })
      .catch((err) => {
        toast({
          title: "Erro ao atualizar cliente",
          description: err.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    if (isOpen) {
      setValue("customer", editData?.customer);
      setValue("splitPercentage", editData?.splitPercentage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      {
        <Box>
          <Tooltip
            label="Exibir clientes"
            //fixa o tooltip no botão chackra
          >
            <CustomButton
              onClick={onOpen}
              colorScheme={editData ? "blue" : "green"}
            >
              {editData ? "Alterar Split" : "Adicionar Split"}
            </CustomButton>
          </Tooltip>
        </Box>
      }
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {editData ? "Editar Split" : "Adicionar Split"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack mb={5} spacing={3} alignItems="flex-start">
                <FormRemoteSelectInput
                  control={control}
                  label="Cliente Recebedor"
                  loadDataFn={({ value, cb }) =>
                    findAllClients({ filter: value }).then((retorno) => {
                      if (retorno?.registers?.length > 0) {
                        cb(
                          retorno.registers?.map((d: any) => ({
                            label: d.name,
                            value: d.id,
                          }))
                        );
                      } else {
                        cb([]);
                      }
                    })
                  }
                  name="customer"
                />
                <PercentageNumberInput
                  label="Percentual"
                  name="splitPercentage"
                  control={control}
                  rules={{
                    required: "Campo obrigatório",
                    min: {
                      value: 0,
                      message: "O valor mínimo é 0",
                    },
                    max: {
                      value: 100,
                      message: "O valor máximo é 100",
                    },
                  }}
                />
              </VStack>
            </ModalBody>
            <ModalFooter>
              <CustomButton
                isLoading={isLoading}
                colorScheme="blue"
                onClick={handleSubmit(sendClientEye)}
              >
                Salvar
              </CustomButton>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default AddSplitEye;
