import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  VStack,
  Tooltip,
  Text,
  Card,
  CardHeader,
  CardBody,
  Grid,
  Icon,
  Skeleton,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { findMenuProductGroups, listEyeClients, removeClientEye } from "./api";
import AddSplitEye from "./AddSplitEye";
import CustomButton from "components/CustomButton";
import { ClientEye, ItemGroupMenu } from "./types/ResponseEyeClient";
import { FaCheck } from "react-icons/fa";
import ConfirmButton from "components/CustomButtonConfirm";

export interface Company {
  id: string;
  name: string;
  document: string;
  status: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IntegrationResponse {
  id: string;
  name: string;
  type: string;
  user: string;
  createdAt: Date;
  identifierUrl: string;
  active: boolean;
}

const SplitEyeGroup: React.FC<{
  group: ItemGroupMenu;
  data: ClientEye[];
  refetch: () => void;
  eyeIntegrationId: number;
  integrationId: string;
}> = ({ group, data, refetch, eyeIntegrationId, integrationId }) => {
  const clients = data?.filter(
    (client) => +client.productGroupId === +group.product_group.id
  );

  const handleRemoveClient = (clientId: string) => {
    removeClientEye(clientId).then(() => {
      refetch();
    });
  };

  return (
    <>
      {!clients?.length && (
        <VStack w="100%" spacing={3}>
          <Text>Nenhum cliente encontrado</Text>
          <AddSplitEye
            eyeIntegrationId={eyeIntegrationId}
            integrationId={integrationId}
            refetch={refetch}
            group={{
              label: group.product_group.name,
              value: String(group.product_group.id),
            }}
          />
        </VStack>
      )}
      {clients?.map((client) => (
        <VStack key={client.id} w="100%" spacing={3}>
          <Text>Cliente: {client.customer.name}</Text>
          <Text>
            Percentual:{" "}
            <Text fontWeight="bold" as="span">
              {client.splitPercentage}%
            </Text>
          </Text>{" "}
          <Text>
            Status: <Icon as={FaCheck} color="green" />
          </Text>
          <AddSplitEye
            key={client.id}
            eyeIntegrationId={eyeIntegrationId}
            group={{
              label: client.productName,
              value: String(client.productGroupId),
            }}
            integrationId={integrationId}
            refetch={refetch}
            editData={{
              customer: {
                label: client.customer.name,
                value: client.customer.id,
              },
              id: client.id,
              splitPercentage: client.splitPercentage,
            }}
          ></AddSplitEye>
          <ConfirmButton
            colorScheme="red"
            variant="ghost"
            onClick={() => handleRemoveClient(String(client.id))}
          >
            Remover
          </ConfirmButton>
        </VStack>
      ))}
    </>
  );
};

const ShowEyeClients: React.FC<{
  eyeIntegrationId: number;
  integrationId: string;
  menuId: number;
}> = ({ eyeIntegrationId, integrationId, menuId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data, refetch } = useQuery(
    ["listEyeClients", eyeIntegrationId],
    () => listEyeClients(eyeIntegrationId),
    {
      enabled: isOpen,
    }
  );

  const { data: productGroups, isLoading } = useQuery(
    ["productGroups", eyeIntegrationId],
    () =>
      findMenuProductGroups(menuId, {
        integrationId,
        page: 0,
        limit: 40,
      }),
    {
      enabled: isOpen,
    }
  );

  return (
    <>
      {
        <Tooltip label="Exibir clientes">
          <CustomButton onClick={onOpen} colorScheme="blue">
            Ver Split
          </CustomButton>
        </Tooltip>
      }
      {isOpen && (
        <Modal
          size={{
            base: "full",
            md: "4xl",
          }}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Clientes do Split</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Grid
                templateColumns="repeat(auto-fill, minmax(200px, 1fr))"
                gap={3}
              >
                {isLoading
                  ? [...Array(10)].map((_, index) => (
                      <Skeleton key={index} height={200} />
                    ))
                  : productGroups
                      ?.sort(
                        (a, b) => a.order_product_group - b.order_product_group
                      )
                      ?.map((group) => (
                        <Card key={group.id} minW={200} minHeight={200}>
                          <CardHeader p={2} backgroundColor={group.color}>
                            <Text fontWeight="bold" color="white" noOfLines={1}>
                              {group.product_group?.name}
                            </Text>
                          </CardHeader>
                          <CardBody>
                            <SplitEyeGroup
                              eyeIntegrationId={eyeIntegrationId}
                              integrationId={integrationId}
                              group={group}
                              data={data}
                              refetch={refetch}
                            />
                          </CardBody>
                        </Card>
                      ))}
              </Grid>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default ShowEyeClients;
