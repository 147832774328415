import { FormControl, FormErrorMessage, Input } from "@chakra-ui/react";
import { AsyncProps } from "chakra-react-select";
import React, { PropsWithoutRef } from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import InputMask from "react-input-mask";
import FormLabelCustom from "./FormLabel";

interface InputProps
  extends PropsWithoutRef<Omit<AsyncProps<any, any, any>, `onChange`>> {
  control: Control<any>;
  label: string;
  labelProps?: any;
  type: "password" | "text" | "email" | "number" | "datetime-local" | "tel";
  name: string;
  placeholder?: string;
  rules?: RegisterOptions<any>;
  mask: string;
  onChange?: (e: string) => void;
}

const InputMaskForm: React.FC<InputProps> = ({
  control,
  label,
  rules,
  name,
  placeholder,
  type,
  labelProps = {},
  mask,
  onChange: customOnChange,
  onBlur: customOnBlur,
  ...props
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange, name, value },
        fieldState: { invalid, error },
      }) => (
        <FormControl isInvalid={invalid}>
          <FormLabelCustom label={label} name={name} props={labelProps} />
          <InputMask
            mask={mask}
            value={value}
            maskChar={null}
            onChange={(e) => {
              onChange(e);
              customOnBlur && customOnBlur(e);
              customOnChange && customOnChange(e);
            }}
          >
            {(inputProps) => (
              <Input
                bg="white"
                name={name}
                {...props}
                {...inputProps}
                size={"sm"}
                type={type}
                fontSize={"sm"}
                ms={{ base: "0px", md: "0px" }}
                placeholder={placeholder}
                required
              />
            )}
          </InputMask>

          <FormErrorMessage>
            {error?.message || "Campo inválido"}
          </FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

export default InputMaskForm;
